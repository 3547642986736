import * as Sentry from '@sentry/react';
import {
  FindStockItemQuery,
  PrintLabelsDocument,
  PrintLabelsMutation,
  PrintLabelsMutationVariables,
} from '../generated/graphql';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '.';
import apollo from '../services/apollo';
import { v4 as uuid } from 'uuid';

export enum PrintQueueItemStatus {
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
}

export enum Step {
  DOCUMENT_SELECTION = 'DOCUMENT_SELECTION',
  CUSTOM_PRINT = 'CUSTOM_PRINT',
  PRESET_SELECTION = 'PRESET_SELECTION',
}

export type RunProduct = NonNullable<FindStockItemQuery['byCode']>;
export interface RunPlan {
  id: string;
  product: RunProduct;
  printed: number;
  quantity: number | null;
}

export interface PrintQueueItem {
  id: number;
  documentName: string;
  status: PrintQueueItemStatus;
  errorText: string | null;
  fade: boolean;
}

export interface UnitNumberData {
  stockCode: string;
  unitNumber: string;
}

export interface AppState {
  step: Step;
  printDocumentId: string | null;

  printData: Record<string, string>;
  selectedPresetIndices: number[];

  nextPrintQueueId: number;
  printQueue: PrintQueueItem[];

  runArray: RunPlan[];
  unitNumbers: UnitNumberData[];
  printer: string;
  stickerPrinter: string;
  hasWrapper: boolean;

  currentRunId: string | null;
}

const initialState: AppState = {
  step: Step.DOCUMENT_SELECTION,
  printDocumentId: null,

  printData: {},
  selectedPresetIndices: [],

  nextPrintQueueId: 0,
  printQueue: [],
  runArray: [],
  unitNumbers: [],
  printer: '',
  stickerPrinter: '',
  hasWrapper: false,

  currentRunId: null,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setStep: (
      state,
      { payload }: PayloadAction<{ step: Step; pushState?: boolean }>,
    ) => {
      setUrl(state.printDocumentId, payload.step, payload.pushState ?? false);
      state.step = payload.step;
    },
    setPrintDocumentId: (state, { payload }: PayloadAction<{ id: string }>) => {
      state.printDocumentId = payload.id;
    },
    reset: (state, { payload }: PayloadAction<{ pushState?: boolean }>) => {
      setUrl(null, Step.DOCUMENT_SELECTION, payload.pushState ?? false);
      state.step = Step.DOCUMENT_SELECTION;
      state.printDocumentId = null;
      state.printData = {};
      state.selectedPresetIndices = [];
    },
    setPrintData: (
      state,
      { payload }: PayloadAction<{ fields: Record<string, string> }>,
    ) => {
      state.printData = payload.fields;
    },
    setSelectedPresetIndices: (
      state,
      { payload }: PayloadAction<{ indices: number[] }>,
    ) => {
      state.selectedPresetIndices = payload.indices;
    },
    addPrintQueueItem: (
      state,
      { payload }: PayloadAction<{ documentName: string }>,
    ) => {
      state.printQueue.push({
        id: state.nextPrintQueueId,
        documentName: payload.documentName,
        status: PrintQueueItemStatus.PENDING,
        errorText: null,
        fade: false,
      });
      state.nextPrintQueueId += 1;
    },
    setPrintQueueItemStatus: (
      state,
      {
        payload,
      }: PayloadAction<{ itemId: number; status: PrintQueueItemStatus }>,
    ) => {
      const item = state.printQueue.find((x) => x.id === payload.itemId);
      if (item) {
        item.status = payload.status;
      }
    },
    setPrintQueueItemErrorText: (
      state,
      { payload }: PayloadAction<{ itemId: number; errorText: string }>,
    ) => {
      const item = state.printQueue.find((x) => x.id === payload.itemId);
      if (item) {
        item.errorText = payload.errorText;
      }
    },
    startPrintItemFade: (
      state,
      { payload }: PayloadAction<{ itemId: number }>,
    ) => {
      const item = state.printQueue.find((x) => x.id === payload.itemId);
      if (item) {
        item.fade = true;
      }
    },
    removePrintQueueItemImmediate: (
      state,
      { payload }: PayloadAction<{ itemId: number }>,
    ) => {
      state.printQueue = state.printQueue.filter(
        (x) => x.id !== payload.itemId,
      );
    },

    //
    // Workstation
    //

    addProductsToRun: (
      state,
      { payload }: PayloadAction<{ items: Omit<RunPlan, 'id' | 'printed'>[] }>,
    ) => {
      const items = new Map(
        state.runArray.map((x) => [x.product.stockCode, x]),
      );
      for (const product of payload.items) {
        items.set(product.product.stockCode, {
          ...product,
          printed: 0,
          id: uuid(),
        });
      }
      state.runArray = [...items.values()];
    },
    printForSingleProductInRun: (
      state,
      { payload }: PayloadAction<{ id: string }>,
    ) => {
      const item = state.runArray.find((x) => x.id === payload.id);
      if (item == null) return;
      if (item.quantity == null || item.printed === item.quantity) return;
      item.printed += 1;
    },
    undoPrintForSingleProductInRun: (
      state,
      { payload }: PayloadAction<{ id: string }>,
    ) => {
      const item = state.runArray.find((x) => x.id === payload.id);
      if (item == null) return;
      if (item.quantity == null || item.printed === 0) return;
      item.printed -= 1;
    },
    removeProductFromRun: (
      state,
      { payload }: PayloadAction<{ id: string }>,
    ) => {
      state.runArray = state.runArray.filter((x) => x.id !== payload.id);
    },
    clearRun: (state) => {
      state.runArray = [];
    },
    setPrinter: (state, { payload }: PayloadAction<string>) => {
      state.printer = payload;
    },
    setStickerPrinter: (state, { payload }: PayloadAction<string>) => {
      state.stickerPrinter = payload;
    },
    setHasWrapper: (state, { payload }: PayloadAction<boolean>) => {
      state.hasWrapper = payload;
    },
    addUnitNumber: (state, { payload }: PayloadAction<UnitNumberData>) => {
      const isDuplicate = state.unitNumbers.find(
        (x) =>
          x.stockCode === payload.stockCode &&
          x.unitNumber === payload.unitNumber,
      );
      if (!isDuplicate) {
        state.unitNumbers.push(payload);
      }
    },

    setCurrentRunId: (state, { payload }: PayloadAction<string | null>) => {
      state.currentRunId = payload;
    },
  },
});

export const AppActions = appSlice.actions;

export const AppSelectors = {};

export default appSlice.reducer;

function setUrl(documentId: string | null, step: string, pushState: boolean) {
  let strategy = '';
  if (step === Step.CUSTOM_PRINT) strategy = 'custom';
  if (step === Step.PRESET_SELECTION) strategy = 'preset';

  if (documentId == null || strategy === '') {
    if (pushState) window.history.pushState(null, '', '');
    return;
  }

  if (pushState) {
    window.history.pushState(
      null,
      '',
      `/?document=${documentId}&strategy=${strategy}`,
    );
  }
}

function removePrintQueueItem({ itemId }: { itemId: number }) {
  return (dispatch: AppDispatch) => {
    dispatch(AppActions.startPrintItemFade({ itemId }));
    setTimeout(() => {
      dispatch(AppActions.removePrintQueueItemImmediate({ itemId }));
    }, 220);
  };
}

export function print(
  documentName: string,
  commands: {
    documentName: string;
    printerName: string;
    printQuantity: number;
    data: Record<string, string>;
  }[],
  shouldReset: boolean,
) {
  return (dispatch: AppDispatch, getState: () => AppState) => {
    const itemId = getState().nextPrintQueueId;
    dispatch(AppActions.addPrintQueueItem({ documentName }));

    if (shouldReset) {
      dispatch(AppActions.reset({ pushState: true }));
    }

    apollo
      .mutate<PrintLabelsMutation, PrintLabelsMutationVariables>({
        mutation: PrintLabelsDocument,
        variables: {
          input: {
            commands: commands.map((x) => ({
              templateId: x.documentName,
              printerId: x.printerName,
              printQuantity: x.printQuantity,
              data: Object.entries(x.data).map(([key, value]) => ({
                field: key,
                value,
              })),
            })),
          },
        },
      })
      .then(() => {
        dispatch(
          AppActions.setPrintQueueItemStatus({
            itemId,
            status: PrintQueueItemStatus.COMPLETED,
          }),
        );
        setTimeout(() => {
          dispatch(removePrintQueueItem({ itemId }));
        }, 1000);
      })
      .catch((error) => {
        Sentry.captureException(error);
        dispatch(
          AppActions.setPrintQueueItemStatus({
            itemId,
            status: PrintQueueItemStatus.FAILED,
          }),
        );
        dispatch(
          AppActions.setPrintQueueItemErrorText({
            itemId,
            errorText: 'Unknown reason',
          }),
        );
        setTimeout(() => {
          dispatch(removePrintQueueItem({ itemId }));
        }, 5000);
      });
  };
}

function parseSearchQuery() {
  const paramsObj: Record<string, string> = {};

  const search = window.location.search;
  if (search === '') return paramsObj;

  const withoutQuestionMark = search.substr(1);
  const params = withoutQuestionMark.split('&');
  for (const assignment of params) {
    const operands = assignment.split('=');
    //@ts-ignore
    paramsObj[operands[0]] = operands[1];
  }

  return paramsObj;
}

export function processUrl(pushState?: boolean) {
  return (dispatch: AppDispatch) => {
    const params = parseSearchQuery();

    if (Object.keys(params).length === 0) {
      dispatch(AppActions.reset({ pushState }));
      return;
    }

    const documentId = params.document || null;
    if (documentId == null) return;

    const strategy = params.strategy;

    let step = null;
    if (strategy === 'preset') step = Step.PRESET_SELECTION;
    if (strategy === 'custom') step = Step.CUSTOM_PRINT;
    if (!step) return;

    dispatch(AppActions.setPrintDocumentId({ id: documentId }));
    dispatch(AppActions.setStep({ step, pushState }));
  };
}

export function clearFields() {
  return (dispatch: AppDispatch) => {
    dispatch(AppActions.setPrintData({ fields: {} }));
    dispatch(AppActions.setSelectedPresetIndices({ indices: [] }));
  };
}
