import {
  Button,
  CircularProgress,
  InputAdornment,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import React, { useState } from 'react';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import {
  OutfeedPackUnitOfMeasure,
  useRunAddOutfeedPackUsingPlanMutation
} from '../../../generated/graphql';
import { useAppSelector } from '../../../store';
import Numpad from '../../Utility/Numpad';
import { HeaderStyle, PlanLine, qtyDisplayString } from './ProductSelect';

export interface PrintPlannedProductProps {
  plannedProduct: PlanLine;
  back: () => void;
  runId: string;
  timberSmartRunTypeId: string;
}

const PrintPlannedProduct: React.FC<PrintPlannedProductProps> = ({
  plannedProduct,
  back,
  runId,
  timberSmartRunTypeId,
}) => {
  const printer = useAppSelector((s) => s.printer);
  const wipProduct = !plannedProduct.finishedProduct?.description;
  const plannedInLm =
    !plannedProduct.piecesPlanned && plannedProduct.linealPlanned;
  const linealCompleted = plannedProduct.linealCompleted ?? 0;
  const setLength = plannedProduct.wipProduct.setLength || 0;
  const description = wipProduct
    ? plannedProduct.wipProduct.description
    : plannedProduct.finishedProduct?.description;
  const stockCode = wipProduct
    ? plannedProduct.wipProduct.id
    : plannedProduct.finishedProduct?.stockCode;
  // Only allows a UOM of 'PCE' if the product has a set length, which is
  // required by timbersmart
  const uom = plannedInLm
    ? OutfeedPackUnitOfMeasure.Lm
    : setLength
    ? OutfeedPackUnitOfMeasure.Pce
    : OutfeedPackUnitOfMeasure.Lm;

  const [runAddOutfeedPackUsingPlanMutation, { loading }] =
    useRunAddOutfeedPackUsingPlanMutation();
  const [quantity, setQuantity] = useState(
    plannedProduct.finishedProduct?.standardPackSize?.toString() || '',
  );

  const [error, setError] = useState(false);

  const plannedQtyWarning = () => {
    // No warning if nothing is planned, meaning it is a 'recovery' product.
    if (!plannedProduct.piecesPlanned && !plannedProduct.linealCompleted) {
      return false;
    }

    if (plannedInLm) {
      return (
        plannedProduct.linealPlanned! < linealCompleted + parseFloat(quantity)
      );
    } else {
      return (
        plannedProduct.piecesPlanned! <
        linealCompleted / setLength + parseFloat(quantity)
      );
    }
  };
  /** @constant {string}
   * Name of the server which hosts the printers */
  //
  const serverName: string= 'services01';

  const addToOutfeed = async () => {
    if (timberSmartRunTypeId === '3' && parseFloat(quantity) < 1) {
      setError(true);
      return;
    }
    setError(false);
    const send = async () => {
      if (!plannedQtyWarning()) {
        return true;
      }
      const res = await Swal.fire({
        title: 'Are you sure?',
        text: 'You will exceed the planned quantity with this print.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d35f5f',
        cancelButtonColor: '#638680',
        confirmButtonText: 'Print',
      }).then((result) => result.isConfirmed);
      return res;
    };

    if (await send()) {
      await runAddOutfeedPackUsingPlanMutation({
        variables: {
          input: {
            printer: `\\\\${serverName}\\${printer}`,
            quantity: quantity ? parseInt(quantity) : 0,
            runOutfeedPlanLineId: plannedProduct.id,
            unitOfMeasure: uom,
          },
        },
        refetchQueries: ['Run', 'RecentPackLogs'],
        awaitRefetchQueries: true,
      })
        .then((res) => {
          if (
            res.errors ||
            res.data?.runAddOutfeedPackUsingPlan.success === false
          ) {
            Swal.fire({
              icon: 'error',
              title: 'Something went wrong',
              text:
                res.data?.runAddOutfeedPackUsingPlan.message ??
                'Unknown error. Please contact IT if this continues.',
            });
          }
        })
        .catch(() => {
          Swal.fire({
            icon: 'error',
            title: 'Unknown Error',
            text: 'Failed to print label. Please contact IT if this continues.',
          });
        });
      back();
    }
  };
  if (loading) {
    return (
      <CircularProgress
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
        }}
        color="primary"
      />
    );
  }

  return (
    <PrintPlannedProductStyle>
      <HeaderStyle>
        <Button
          className="back"
          variant="contained"
          color="error"
          onClick={back}
        >
          Back
        </Button>
        <div className="header-text">
          <Typography variant="h4" className="run">
            {runId}
          </Typography>
        </div>
      </HeaderStyle>
      <div className="container">
        <ListItem className="row">
          <ListItemText
            className="item"
            primary="Description"
            style={{ width: '60%' }}
            secondary={description}
          />
          <ListItemText
            primary="Code"
            className="item"
            style={{ width: '20%' }}
            secondary={stockCode}
          />
          <ListItemText
            className="item"
            style={{ width: '20%' }}
            primary="Unit Number"
            secondary={plannedProduct.unitNumber ?? 'N/A'}
          />
        </ListItem>
        <ListItem className="row">
          <ListItemText
            className="item"
            primary="Stacking Instructions"
            style={{ width: '60%' }}
            secondary={plannedProduct.packBuild}
          />
          <ListItemText
            style={{ width: '20%' }}
            className="item"
            primary="Next Action"
            secondary={plannedProduct.nextAction}
          />
          {/* Empty component to retain clean layout. */}
          <ListItemText style={{ width: '20%' }} />
        </ListItem>
      </div>
      <div className="inputs">
        <Numpad value={quantity} setValue={setQuantity} />
        <div className="qty-print">
          <Typography className="qty" variant="h3">
            {qtyDisplayString(
              plannedProduct,
              linealCompleted,
              plannedProduct.wipProduct.setLength || 0,
            )}
          </Typography>
          <div style={{ height: '2em' }}>
            {timberSmartRunTypeId === "3" && parseFloat(quantity) < 1 && (
              <div className={'planned-qty-warning'}>
                <WarningRoundedIcon />
                <Typography>
                  Quantity must be greater than 0
                </Typography>
              </div>
            )}

            {plannedQtyWarning() && parseFloat(quantity) >= 1 && (
              <div className={'planned-qty-warning'}>
                <WarningRoundedIcon />
                <Typography>
                  Planned quantity will be exceeded by this print.
                </Typography>
              </div>
            )}
          </div>
          <TextField
            className="qty"
            variant="outlined"
            value={quantity}
            type={'number'}
            required={timberSmartRunTypeId === "3"}
            label={timberSmartRunTypeId === "3" ? true : null}
            error={error}
            helperText={error ? "Quantity must be greater than 0" : ""}
            onChange={(e) => setQuantity(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{uom}</InputAdornment>
              ),
            }}
          />
          <Button
            onClick={addToOutfeed}
            disabled={quantity === '' || quantity === '0'}
            className="print"
            variant="contained"
          >
            Print
          </Button>
          <div style={{ height: '2em' }} />
        </div>
      </div>
    </PrintPlannedProductStyle>
  );
};

export default PrintPlannedProduct;

const PrintPlannedProductStyle = styled.div`
  margin-left: 1em;
  margin-right: 1em;

  animation: fadeIn 0.5s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .MuiFormLabel-asterisk{
    color: red;
  }
  
  .container {
    margin: 20px 0;

    .item {
      p {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }

  .planned-qty-warning {
    display: flex;
    flex-direction: row;
    color: #bc2f5ed1;
    margin-bottom: 0.5em;
  }

  .inputs {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 320px;
    margin-left: 15px;

    .qty-print {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: flex-end;
      margin-left: 100px;

      .print {
        color: white;
        height: 70px;
      }

      .qty {
        margin-bottom: 13px;
        width: 375px;
      }
    }
  }
`;
