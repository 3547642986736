import RefreshIcon from '@mui/icons-material/Refresh';
import DownloadIcon from '@mui/icons-material/Download';
import { DatePicker } from '@mui/lab';
import {
  Alert,
  Button,
  LinearProgress,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import dateFormat from 'dateformat';
import React, { useCallback, useEffect, useState } from "react";
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import styled from 'styled-components';
import { PackHistoryQuery, usePackHistoryQuery } from '../generated/graphql';
import { DeletePackListAction } from './RecentPackLine';
import { SelectWorkstation } from './SelectWorkstation';
import * as XLSX from 'xlsx';

const TAKE = 10;

const ROW_SIZES = [100, 380, 120, 120, 100, 180, 100];

export const PackHistory: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [stockCode, setStockCode] = useState('');
  const [workstationId, setWorkstationId] = useState<string | null>(null);
  const [unitSearch, setUnitSearch] = useState('');
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(() => {
    const now = new Date();
    now.setMonth(now.getMonth() - 1);
    return now;
  });
  const listRef = React.createRef<FixedSizeList>()

  const global = !!searchQuery;
  const invalidStartDate = startDate == null || isNaN(startDate.getTime());
  const invalidEndDate = endDate != null && isNaN(endDate.getTime());
  const skipQuery = invalidStartDate || invalidEndDate;

  const { data, loading, error, refetch } = usePackHistoryQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: skipQuery,
    variables: {
      packWorkstationId: global ? null : workstationId,
      stockCode: global ? null : stockCode || null,
      take: global ? TAKE : null,
      searchQuery: searchQuery || null,
      startDate:
        global || skipQuery ? null : roundStartDate(startDate!).toISOString(),
      endDate:
        global || skipQuery ? null : roundEndDate(endDate)?.toISOString(),
    },
  });

  useEffect(() => {
    listRef.current?.scrollToItem(0);
  });

  const RenderRow = useCallback(
    ({ index, style }: ListChildComponentProps) => {
      let pack;
      if (unitSearch === '') {
        pack = data?.packLogs[index];
      } else {
        pack = data?.packLogs.filter((log) => {
          return log.unitNumber?.includes(unitSearch);
        })[index];
      }
      if (pack==null) {
        return null;
      }

      return (
        <TableRow
          component="div"
          style={{
            ...style,
            height: 69,
            color: pack.deleted ? '#dcdcdc' : 'black',
          }}
        >
          <TableCell component="div" style={{ width: ROW_SIZES[0] }}>
            {pack.packNumber}
          </TableCell>
          <TableCell component="div" style={{ width: ROW_SIZES[1] }}>
            {pack.stockItem.stockCode}
            <br />
            <span style={{ fontSize: '0.75rem' }}>
              {pack.stockItem.description}
            </span>
          </TableCell>
          <TableCell component="div" style={{ width: ROW_SIZES[2] }}>
            {pack.workstation.name}
          </TableCell>
          <TableCell component="div" style={{ width: ROW_SIZES[3] }}>
            {pack.tally.length > 0 ? (
              <Tooltip title={showTally(pack.tally)}>
                <div>
                  {pack.quantity}{' '}
                  {pack.stockItem.unitOfMeasure.symbolCapitalized}
                  <br />
                  {pack.tally.length > 0 && <em>Hover to view tally</em>}
                </div>
              </Tooltip>
            ) : (
              <>
                {pack.quantity} {pack.stockItem.unitOfMeasure.symbolCapitalized}
              </>
            )}
          </TableCell>
          <TableCell component="div" style={{ width: ROW_SIZES[4] }}>
            {pack.unitNumber}
            <br />
            {pack.unitNumberCategory}
          </TableCell>
          <TableCell component="div" style={{ width: ROW_SIZES[5] }}>
            {dateFormat(pack.lastUpdated)}
          </TableCell>
          <TableCell component="div" style={{ width: ROW_SIZES[6] }}>
            <div className="delete">
              <DeletePackListAction packId={pack.id} deleted={pack.deleted} />
            </div>
          </TableCell>
        </TableRow>
      );
    },
    [data?.packLogs, unitSearch],
  );

  return (
    <PackHistoryStyle>
      <div className="header">
        <TextField
          id="outlined-basic"
          variant="standard"
          autoComplete="off"
          label="Search Pack Number (Global)"
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ width: 250 }}
          value={searchQuery}
        />
        <TextField
          id="outlined-basic"
          variant="standard"
          autoComplete="off"
          label="Search Unit Number"
          onChange={(e) => setUnitSearch(e.target.value)}
          value={unitSearch}
         />
        <TextField
          disabled={global}
          id="outlined-basic"
          variant="standard"
          autoComplete="off"
          label="Filter Stock Code (Exact)"
          onChange={(e) => setStockCode(e.target.value)}
          value={stockCode}
        />
        <SelectWorkstation
          disabled={global}
          workstationId={workstationId}
          setWorkstationId={setWorkstationId}
        />

        <DatePicker
          disabled={global}
          label="Start Date"
          value={startDate}
          onChange={setStartDate}
          disableFuture
          inputFormat="dd/MM/yyyy"
          mask="__/__/____"
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              error={invalidStartDate}
            />
          )}
        />
        <DatePicker
          disabled={global}
          label="End Date"
          value={endDate}
          onChange={setEndDate}
          disableFuture
          inputFormat="dd/MM/yyyy"
          mask="__/__/____"
          renderInput={(params) => (
            <TextField {...params} variant="standard" error={invalidEndDate} />
          )}
        />

        <Button startIcon={<RefreshIcon />} onClick={() => refetch()}>
          Refresh
        </Button>
        <Button
          disabled={data == null}
          startIcon={<DownloadIcon />}
          onClick={() => createResultsFile(data!.packLogs)}
        >
          Export
        </Button>
      </div>
      <div className="data">
        <LinearProgress
          style={{
            visibility: loading ? 'visible' : 'hidden',
            width: 1340,
            marginBottom: '1em',
          }}
        />
        {error && (
          <Alert severity="error" className="no-results">
            Failed to fetch.
          </Alert>
        )}
        {data?.packLogs && data?.packLogs.length > 0 ? (
          <Table component="div" style={{ display: 'block' }}>
            <TableHead component="div">
              <TableRow component="div">
                <TableCell component="div" style={{ width: ROW_SIZES[0] }}>
                  Pack Number
                </TableCell>
                <TableCell component="div" style={{ width: ROW_SIZES[1] }}>
                  Stock Item
                </TableCell>
                <TableCell component="div" style={{ width: ROW_SIZES[2] }}>
                  Workstation
                </TableCell>
                <TableCell component="div" style={{ width: ROW_SIZES[3] }}>
                  Quantity
                </TableCell>
                <TableCell component="div" style={{ width: ROW_SIZES[4] }}>
                  Unit
                </TableCell>
                <TableCell component="div" style={{ width: ROW_SIZES[5] }}>
                  Created
                </TableCell>
                <TableCell component="div" style={{ width: ROW_SIZES[6] }} />
              </TableRow>
            </TableHead>
            <FixedSizeList
              ref={listRef}
              itemCount={data?.packLogs.length}
              itemSize={69}
              width={1340}
              height={600}
            >
              {RenderRow}
            </FixedSizeList>
          </Table>
        ) : (
          !loading && (
            <Alert severity="warning" className="no-results">
              Could not find any packs
            </Alert>
          )
        )}
      </div>
    </PackHistoryStyle>
  );
};

const PackHistoryStyle = styled.div`
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;

  .MuiTableCell-root {
    padding: 4px 16px;
  }

  .delete {
    height: 61px;
    display: grid;
    place-items: center;
  }

  .header {
    display: flex;
    gap: 1em;
    align-items: center;
  }
`;

// Beginning of day
function roundStartDate(start: Date) {
  return new Date(start.setHours(0, 0, 0, 0));
}

// Start of next day
function roundEndDate(end: Date | null) {
  if (end == null) return null;
  const res = new Date(end);
  res.setDate(res.getDate() + 1);
  res.setHours(0, 0, 0, 0);
  return res;
}

type PackLog = PackHistoryQuery['packLogs'][0];

function createResultsFile(data: PackLog[]) {
  const headings = [
    'ID',
    'Deleted',
    'Pack Number',
    'Last Updated',
    'Quantity',
    'UOM',
    'Unit Number',
    'Unit Number Category',
    'Workstation',
    'Stock Code',
    'Description',
    'Tally',
  ];

  const worksheet = XLSX.utils.aoa_to_sheet([
    headings,
    ...data.map((row) => [
      row.id,
      row.deleted ? 'Yes' : 'No',
      row.packNumber,
      dateFormat(row.lastUpdated, 'yyyy-mm-dd HH:MM:ss'),
      row.quantity,
      row.stockItem.unitOfMeasure.symbolCapitalized,
      row.unitNumber ?? '',
      row.unitNumberCategory ?? '',
      row.workstation.name,
      row.stockItem.stockCode,
      row.stockItem.description,
      showTally(row.tally),
    ]),
  ]);

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Results');
  XLSX.writeFile(workbook, 'Pack History Results.xlsx');
}

function showTally(tally: PackLog['tally']): string {
  return tally.map((x) => `${x.pieces}/${x.length}`).join(', ');
}
