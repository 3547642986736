import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import * as Integrations from '@sentry/integrations';
import { excludeGraphQLFetch } from 'apollo-link-sentry';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import App from './App';
import { Error } from './components/Utility/Error';
import { env, releaseProfile } from './runtime-environment';
import 'sweetalert2/dist/sweetalert2.css';

import apollo from './services/apollo';
import { ApolloProvider } from '@apollo/client/react';

const rootElement = document.getElementById('root');

const history = createBrowserHistory();

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://4abca413923d4c00aaf19a8fd537e5c0@o925760.ingest.sentry.io/5934832',
    release: `universalprint@${env.version}`,
    environment: releaseProfile(),
    normalizeDepth: 10,
    // We add them with more detail through apollo-link-sentry
    beforeBreadcrumb: excludeGraphQLFetch,
    integrations: [
      new TracingIntegrations.BrowserTracing({
        tracingOrigins: ['localhost', '.genia.co.nz'],
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new Integrations.CaptureConsole({
        levels: ['warn', 'error'],
      }),
      new Integrations.Offline(),
    ],
    // We don't have millions of users, better safe than sorry
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <Sentry.ErrorBoundary
    fallback={<Error fullscreen errorText="Uh-oh. The app crashed." />}
    showDialog
  >
    <ApolloProvider client={apollo}>
      <Router history={history}>
        <App />
      </Router>
    </ApolloProvider>
  </Sentry.ErrorBoundary>,
  rootElement,
);
