import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import React, { useState } from 'react';
import { LabelTemplatePreview } from '../../types';
import { Category } from './Category';

export interface DumbDocumentListProps {
  documents: LabelTemplatePreview[];
  selectDocument: (docId: string, type: 'preset' | 'custom') => void;
}

const DumbDocumentList: React.FC<DumbDocumentListProps> = ({
  documents,
  selectDocument,
}) => {
  const [openCategory, setOpenCategory] = useState<string>('');

  const categorized: Record<string, LabelTemplatePreview[]> = {};
  for (const doc of documents) {
    const category = doc.category ?? 'Uncategorised';
    const categoryDocuments = categorized[category];
    if (categoryDocuments == null) {
      categorized[category] = [doc];
    } else {
      categoryDocuments.push(doc);
    }
  }

  return (
    <Table aria-label="collapsible table">
      <TableBody>
        {Object.entries(categorized).map(([category, docs]) => (
          <Category
            key={category}
            categoryName={category}
            toggle={() => {
              setOpenCategory(category === openCategory ? '' : category);
            }}
            selected={openCategory === category}
            documents={docs}
            selectDocument={selectDocument}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default DumbDocumentList;
