import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Modal, Paper, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import source from '../../CHANGELOG.md';
import { env, releaseProfile } from '../runtime-environment';

export const VersionNumber: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [state, setState] = useState('');

  useEffect(() => {
    fetch(source)
      .then((res) => res.text())
      .then((post) => setState(post))
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
      });
    return () => {};
  }, []);

  return (
    <>
      <VersionStyle>
        <Typography
          onClick={() => setOpen(true)}
          align="left"
          style={{ textTransform: 'uppercase' }}
        >
          {env.version} {releaseAnnotation()}
        </Typography>
      </VersionStyle>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalStyle>
          <IconButton
            onClick={() => setOpen(false)}
            style={{
              position: 'absolute',
              top: '1em',
              right: '1em',
            }}
            size="large"
          >
            <CloseIcon style={{ padding: 0 }} />
          </IconButton>
          <ReactMarkdown>{state}</ReactMarkdown>
        </ModalStyle>
      </Modal>
    </>
  );
};

function releaseAnnotation() {
  const profile = releaseProfile();
  if (profile === 'local') return 'local';
  if (profile === 'staging') return 'staging';
  if (profile === 'feat-eval') return 'feature evaluation';
  return '';
}

const VersionStyle = styled.div`
  position: fixed;
  left: 10px;
  top: 5px;
  color: #ccc;

  :hover {
    cursor: pointer;
  }
`;

const ModalStyle = styled(Paper)`
  font-family: Roboto;
  overflow-y: auto;
  left: 15vw;
  top: 5vh;
  width: 70vw;
  height: 90vh;
  position: fixed;
  outline: none;
  padding: 2em;

  & * {
    padding: 0.3em;
    list-style-position: inside;
  }

  h2 {
    padding-top: 1.5em;
  }

  li {
    padding-left: 10px;
  }
`;
