import React from 'react';
import styled from 'styled-components';
import Logo from '../../assets/companyLogo.png';

export interface LoadingProps {
  fullscreen?: boolean;
}

export const Loading: React.FC<LoadingProps> = ({ fullscreen }) => (
  <LoadingStyling fullscreen={fullscreen}>
    <div className="Wrapper">
      <div>
        <img alt="logo" src={Logo} />
      </div>
    </div>
  </LoadingStyling>
);

const LoadingStyling = styled.div<LoadingProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .Wrapper {
    display: flex;
    justify-content: center;
  }

  ${(props) =>
    props.fullscreen &&
    `
    width: 100vw;
    height: 100vh;
    align-items: center;
  `}

  img {
    width: 250px;
    animation-name: heartBeat;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
  }

  @keyframes heartBeat {
    0% {
      transform: scale(none);
      -webkit-transform: scale(none);
    }

    20% {
      transform: scale(1.2, 1.2);
      -webkit-transform: scale(1.2, 1.2);
    }

    30% {
      transform: scale(1.05, 1.05);
      -webkit-transform: scale(1.05, 1.05);
    }

    45% {
      transform: scale(1.25, 1.25);
      -webkit-transform: scale(1.25, 1.25);
    }

    50% {
      transform: scale(none, none);
      -webkit-transform: scale(none, none);
    }

    100% {
      transform: scale(none, none);
      -webkit-transform: scale(none, none);
    }
  }
`;
