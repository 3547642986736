import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useAppSelector } from '../../store';
import styled from 'styled-components';
import { PrintQueueItemStatus } from '../../store/reducer';

type DisplayData = { text: string; style: React.CSSProperties };

const itemDisplayData: Record<PrintQueueItemStatus, DisplayData> = {
  [PrintQueueItemStatus.PENDING]: {
    text: 'Pending',
    style: { color: '#aaa' },
  },
  [PrintQueueItemStatus.FAILED]: {
    text: 'Failed',
    style: { color: '#aa1010' },
  },
  [PrintQueueItemStatus.COMPLETED]: {
    text: 'Completed',
    style: { color: '#599633' },
  },
};

export const PrintQueue: React.FC = () => {
  const items = useAppSelector((s) => s.printQueue);

  return (
    <PrintQueueStyle>
      {[...items].reverse().map((item) => {
        const title = `Printing '${item.documentName}'`;
        const subtitle = itemDisplayData[item.status];

        return (
          <Card
            key={item.id}
            style={{ marginBottom: 20, width: 300 }}
            className={item.fade ? 'fading' : ''}
          >
            <CardContent>
              <CardHeader>{title}</CardHeader>
              <Typography style={subtitle.style}>{subtitle.text}</Typography>
              {item.errorText && (
                <Typography style={{ marginTop: 5 }}>
                  {item.errorText}
                </Typography>
              )}
            </CardContent>
          </Card>
        );
      })}
    </PrintQueueStyle>
  );
};

const PrintQueueStyle = styled.div`
  position: fixed;
  right: 20px;
  top: 80px;
  z-index: 10000;
`;
