import React from 'react';
import DocumentList from './DocumentList';
import styled from 'styled-components';
import Container from '@mui/material/Container';

export const DocumentPicker: React.FC = () => (
  <DocumentPickerStyle>
    <Container>
      <h2>Pick a Document</h2>
      <DocumentList />
    </Container>
  </DocumentPickerStyle>
);

const DocumentPickerStyle = styled.div`
  margin-top: 20px;
  h2 {
    margin: 30px 0px;
  }
`;
