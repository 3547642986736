import React from 'react';
import { useAppSelector } from '../store';
import { Step } from '../store/reducer';
import { DocumentPicker } from './Document/DocumentPicker';
import { CustomPrinter } from './PrinterPresets/CustomPrinter';
import { PresetPrinter } from './PrinterPresets/PresetPrinter';

export const Home: React.FC = () => {
  const step = useAppSelector((s) => s.step);

  return (
    <>
      {step === Step.DOCUMENT_SELECTION && <DocumentPicker />}
      {step === Step.CUSTOM_PRINT && <CustomPrinter />}
      {step === Step.PRESET_SELECTION && <PresetPrinter />}
    </>
  );
};
