import React, { useState } from 'react';
import { ErrorMessage } from '../Utility/ErrorMessage';
import { PrinterSelector } from './PrinterSelector';
import { PrintFooter } from './PrintFooter';
import Fuse from 'fuse.js';

import { PresetUploadDownload } from './PresetUploadDownload';
import { useDefaultPrinter } from '../../utils/defaultPrinters';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import styled from 'styled-components';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppDispatch, useAppSelector } from '../../store';
import { AppActions } from '../../store/reducer';
import { CustomFieldsDisplay } from '../CustomFieldsDisplay';
import { initialCustomFieldValues } from '../../utils/helpers';
import Input from '@mui/material/Input/Input';
import Table from '@mui/material/Table/Table';
import Container from '@mui/material/Container';
import { LabelTemplate, LabelTemplateField } from '../../types';
import {
  LabelTemplatePresetKind,
  useLabelTemplateQuery,
} from '../../generated/graphql';

export function applyQuery<T>(data: T[], fields: string[], query: string) {
  const queryStr = query.trim();
  if (queryStr === '') return data;

  // Fuzzy search
  const fuse = new Fuse(data, {
    keys: fields,
    tokenize: true,
    matchAllTokens: true,
    sort: true,
  });
  return fuse.search(query.trim().substring(0, 32));
}

function convertPresetToInternalObject(
  preset: string[],
  fields: LabelTemplateField[],
) {
  const obj: Record<string, string> = {};
  let i = 0;
  const sortedFields = [...fields].sort((a, b) => a.name.localeCompare(b.name));
  for (const field of sortedFields) {
    if (field.preset) {
      if (field.internalName != null) obj[field.internalName] = preset[i];
      i++;
    }
  }
  return obj;
}

function convertPresetToDisplayObject(
  preset: string[],
  presetIndex: number,
  fields: LabelTemplateField[],
) {
  const obj: Record<string, string | number> & { presetIndex: number } = {
    presetIndex,
  };

  let i = 0;
  const sortedFields = [...fields].sort((a, b) => a.name.localeCompare(b.name));
  for (const field of sortedFields) {
    if (field.preset) {
      obj[field.name] = preset[i];
      i++;
    }
  }
  return obj;
}

export interface PresetTableProps {
  document: LabelTemplate;
  selectedItems: Set<number>;
  selectItem: (idx: number) => void;
  deselectItem: (idx: number) => void;
}

const PresetTable: React.FC<PresetTableProps> = ({
  document,
  selectedItems,
  selectItem,
  deselectItem,
}) => {
  const [query, setQuery] = useState('');

  if (!document.presets) {
    return (
      <div style={{ marginTop: 20 }}>
        No presets have yet been created for this label. Upload some?
      </div>
    );
  }

  const presetObjects = document.presets.map((preset, i) =>
    convertPresetToDisplayObject(preset, i, document.fields),
  );
  const presetFields = document.fields.filter((d) => d.preset);
  const filteredPresets = applyQuery(
    presetObjects,
    presetFields.map((p) => p.name),
    query,
  );

  return (
    <PresetTableStyle>
      <h3>Presets</h3>
      <Input
        className="Input"
        placeholder="Filter"
        value={query}
        onChange={(e) => {
          setQuery(e.target.value.substring(0, 32));
        }}
      />
      <div className="TableContainer">
        <Table>
          <thead>
            <tr>
              <th>#</th>
              {presetFields.map((field, i) => (
                <th key={i}>{field.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredPresets.map((preset) => {
              const selected = selectedItems.has(preset.presetIndex);
              return (
                <tr
                  key={preset.presetIndex}
                  style={selected ? { backgroundColor: '#ededed' } : {}}
                  className="hover-item"
                  onClick={() =>
                    (selected ? deselectItem : selectItem)(preset.presetIndex)
                  }
                >
                  <th>{preset.presetIndex}</th>
                  {presetFields.map((field) => {
                    const value = preset[field.name];
                    return <td key={field.name}>{value}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </PresetTableStyle>
  );
};

export const PresetPrinter: React.FC = () => {
  const documentId = useAppSelector((s) => s.printDocumentId)!;
  const customData = useAppSelector((s) => s.printData);
  const selectedPresetIndices = useAppSelector((s) => s.selectedPresetIndices);
  const dispatch = useAppDispatch();

  const { data, loading, error, refetch } = useLabelTemplateQuery({
    variables: { id: documentId },
  });

  const template = data?.labelTemplate ?? undefined;
  const [printer, printers, setPrinter] = useDefaultPrinter(template);

  const selectItem = (index: number) => {
    if (template?.presetKind === LabelTemplatePresetKind.Single) {
      dispatch(
        AppActions.setSelectedPresetIndices({
          indices: [index],
        }),
      );
      return;
    }

    const newSet = new Set(selectedPresetIndices);
    newSet.add(index);
    dispatch(AppActions.setSelectedPresetIndices({ indices: [...newSet] }));
  };

  const deselectItem = (index: number) => {
    dispatch(
      AppActions.setSelectedPresetIndices({
        indices: selectedPresetIndices.filter((x) => x !== index),
      }),
    );
  };

  const clearAll = () => {
    dispatch(AppActions.setSelectedPresetIndices({ indices: [] }));
  };

  const setCustomDataValue = (fieldName: string, value: string) => {
    dispatch(
      AppActions.setPrintData({
        fields: {
          ...customData,
          [fieldName]: value,
        },
      }),
    );
  };

  const presetFields = template?.fields.filter((x) => x.preset) ?? null;
  const customFields =
    template?.fields.filter((x) => !x.preset && x.internalName != null) ?? null;

  const indicesSet = new Set(selectedPresetIndices);

  const content = (() => {
    if (loading) return <div>Loading...</div>;
    if (error) return <ErrorMessage />;
    if (template) {
      return (
        <div>
          <CustomFieldsDisplay
            fields={customFields!}
            values={customData}
            setValue={setCustomDataValue}
          />
          <PresetTable
            document={template}
            selectedItems={indicesSet}
            selectItem={selectItem}
            deselectItem={deselectItem}
          />
        </div>
      );
    }
    return <div />;
  })();

  const documentName = template?.name;

  // When we send this information, all fields must have a value, even if blank
  const customDataToSend = customFields
    ? { ...initialCustomFieldValues(customFields!), ...customData }
    : null;

  const dataToSend =
    template && selectedPresetIndices.length !== 0
      ? template.presets
          .filter((_, i) => indicesSet.has(i))
          .map((p) => {
            const presetObject = convertPresetToInternalObject(
              p,
              presetFields!,
            );
            delete presetObject.presetIndex;
            return {
              ...presetObject,
              ...customDataToSend,
            };
          })
      : null;

  return (
    <ContentStyle>
      <div className="ContentContainer">
        <Container style={{ margin: '10px auto' }}>
          {template && (
            <PreviewSectionStyle>
              <div className="SelectedStyle">
                <span>Selected Products</span>
                <RemoveCircleIcon
                  className="ClearAll"
                  style={{
                    color:
                      selectedPresetIndices.length !== 0
                        ? 'black'
                        : 'lightGrey',
                  }}
                  onClick={clearAll}
                />
                <div className="SelectedProducts">
                  {selectedPresetIndices.map((p) => (
                    <li key={p}>
                      #{p}
                      <DeleteIcon
                        onClick={() => deselectItem(p)}
                        className="DeleteIcon"
                      />
                    </li>
                  ))}
                </div>
              </div>
            </PreviewSectionStyle>
          )}
          <h2 style={{ marginBottom: 20 }}>
            Print Label Presets - {documentName}
          </h2>
          <PrinterSelector
            printer={printer}
            printers={printers}
            setPrinter={setPrinter}
          />
          {content}
          {template && (
            <div style={{ float: 'right', margin: '10px auto' }}>
              <PresetUploadDownload
                documentId={template?.id}
                onUpload={refetch}
              />
            </div>
          )}
        </Container>
      </div>
      <PrintFooter
        documentName={documentName ?? ''}
        printerName={printer}
        data={dataToSend ?? []}
      />
    </ContentStyle>
  );
};

const PreviewSectionStyle = styled.div`
  display: inline-flex;
  flex-direction: row;
  margin-top: 20px;
  margin-left: 20px;

  .SelectedProducts {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    list-style: none;
    height: 100px;
    overflow: auto;
  }

  .ClearAll {
    margin-left: 10px;
    cursor: pointer;
  }

  .DeleteIcon {
    cursor: pointer;
    margin-left: 10px;
  }
`;

const PresetTableStyle = styled.div`
  margin-top: 10px;
  .TableContainer {
    max-height: 350px;
    overflow: auto;
  }
  .Input {
    margin-bottom: 10px;
    width: 30%;
  }

  td,
  th {
    padding: 5px;
  }
`;

const ContentStyle = styled.div`
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  .ContentContainer {
    flex-grow: 1;
    overflow-y: auto;
    width: 100vw;
    padding-bottom: 60px;
  }
`;
