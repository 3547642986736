import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export interface PrinterSelectorProps {
  printer: string;
  printers: string[];
  setPrinter: (p: string) => void;
  style?: React.CSSProperties;
}

export const PrinterSelector: React.FC<PrinterSelectorProps> = ({
  printer,
  printers,
  setPrinter,
  style,
}) => (
  <div
    style={{ ...style, visibility: printers.length > 0 ? 'visible' : 'hidden' }}
  >
    <Select
      style={{ width: '100%' }}
      value={printer}
      onChange={(e) => setPrinter(e.target.value as string)}
    >
      {printers !== null &&
        [
          <MenuItem key={-1} value="" disabled>
            Select Printer
          </MenuItem>,
        ].concat(
          printers.sort().map((printer: string) => (
            <MenuItem key={printer} value={printer}>
              {printer}
            </MenuItem>
          )),
        )}
    </Select>
  </div>
);
