import { configureStore } from '@reduxjs/toolkit';
import rootReducer, { AppState } from './reducer';

import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

export * from './hooks';

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  whitelist: [
    'step',
    'printDocumentId',
    'preset',
    'printData',
    'allProducts',
    'runArray',
    'unitNumbers',
  ],
};

const persisted = persistReducer<AppState>(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persisted,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
