import FormControl from '@mui/material/FormControl/FormControl';
import Input from '@mui/material/Input/Input';
import React from 'react';
import styled from 'styled-components';
import { LabelTemplateFieldKind } from '../generated/graphql';
import { LabelTemplateField } from '../types';

export interface CustomFieldsDisplayProps {
  fields: LabelTemplateField[];
  values: Record<string, string>;
  setValue: (fieldName: string, value: string) => void;
}

export const CustomFieldsDisplay: React.FC<CustomFieldsDisplayProps> = ({
  fields,
  values,
  setValue,
}) => (
  <CustomFieldsDisplayStyle>
    {fields.map((f, i) => (
      <FormControl className="FormStyle" key={i}>
        <Input
          type={
            f.kind === LabelTemplateFieldKind.Multiline ? 'textarea' : 'text'
          }
          rows={
            f.kind === LabelTemplateFieldKind.Multiline && f.defaultLineCount
              ? f.defaultLineCount
              : undefined
          }
          value={values[f.name] || ''}
          className="Input"
          onChange={(e) => setValue(f.name, e.target.value)}
          placeholder={f.extraInfo ? `${f.name} (${f.extraInfo})` : `${f.name}`}
        />
      </FormControl>
    ))}
  </CustomFieldsDisplayStyle>
);

const CustomFieldsDisplayStyle = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  .FormStyle {
    margin-bottom: 5px;
  }
  .Input {
    padding: 10px;
  }
`;
