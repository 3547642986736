import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import React from 'react';
import styled from 'styled-components';
import { LabelTemplatePresetKind } from '../../generated/graphql';
import { LabelTemplatePreview } from '../../types';

export interface DocumentPreviewRowProps {
  doc: LabelTemplatePreview;
  selectDocument: (docId: string, type: 'preset' | 'custom') => void;
}

const DocumentPreviewRow: React.FC<DocumentPreviewRowProps> = ({
  doc,
  selectDocument,
}) => (
  <DocumentPreviewRowStyle key={doc.id}>
    <div className="ItemContainer">
      <div className="Item">
        <div className="DocTitle">{doc.name}</div>
        <ButtonGroup>
          {doc.presetKind !== LabelTemplatePresetKind.None && (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => selectDocument(doc.id, 'preset')}
            >
              Preset
            </Button>
          )}
          <Button
            variant="outlined"
            color="primary"
            onClick={() => selectDocument(doc.id, 'custom')}
          >
            Custom
          </Button>
        </ButtonGroup>
      </div>
    </div>
  </DocumentPreviewRowStyle>
);

export default DocumentPreviewRow;

const DocumentPreviewRowStyle = styled.div`
  .ItemContainer {
    display: flex;
    align-items: center;
    margin: 15px 0;
    .Item {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      height: 38px;
      .DocTitle {
        line-height: 38px;
      }
    }
  }
`;
