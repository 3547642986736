import React, { useRef } from 'react';
import Swal from 'sweetalert2';
import Button, { ButtonProps } from '@mui/material/Button';
import {
  useLabelTemplatePresetDownloadLazyQuery,
  useUploadPresetsMutation,
} from '../../generated/graphql';
import { displayUserErrors } from '../../utils/errors';

export const PresetDownloadButton: React.FC<{ documentId: string }> = ({
  documentId,
}) => {
  const [fetch] = useLabelTemplatePresetDownloadLazyQuery({
    fetchPolicy: 'network-only',
    variables: { id: documentId },
    onCompleted(data) {
      const doc = data?.labelTemplate?.presetDocument;
      if (doc == null) {
        Swal.fire("Couldn't download", 'Well, :(');
        return;
      }

      const a = document.createElement('a');
      a.href = 'data:image/png;base64,' + doc;
      a.download = 'presets.xlsx';
      a.click();
    },
  });

  return <Button onClick={() => fetch()}>Download Presets</Button>;
};

export interface PresetUploadButtonProps {
  documentId: string;
  onUpload: () => void;
}

export const PresetUploadButton: React.FC<PresetUploadButtonProps> = ({
  documentId,
  onUpload,
}) => {
  const [upload] = useUploadPresetsMutation();

  const handleUpload = (file: File) => {
    displayUserErrors(
      'labelTemplateUploadPresets',
      upload({
        variables: {
          input: {
            labelTemplateId: documentId,
            presets: file,
          },
        },
        refetchQueries: ['LabelTemplate'],
        awaitRefetchQueries: true,
      }),
      true,
    ).then(onUpload);
  };

  return <UploadButton onUpload={handleUpload}>Upload Presets</UploadButton>;
};

export type UploadButtonProps = {
  onUpload: (file: File) => void;
  children: React.ReactNode;
} & Pick<ButtonProps, 'variant' | 'color'>;

export function UploadButton({
  onUpload,
  children,
  ...props
}: UploadButtonProps) {
  const formRef = useRef<HTMLFormElement>(null);

  const onChange = (event: any) => {
    if (!event.target?.validity?.valid) return;
    const file = event.target.files[0];
    onUpload(file);
    formRef.current?.reset();
  };

  return (
    <form ref={formRef}>
      <Button {...props} component="label" onChange={onChange}>
        {children}
        <input type="file" hidden />
      </Button>
    </form>
  );
}

export interface PresetUploadDownloadProps {
  documentId: string;
  onUpload: () => void;
}

export const PresetUploadDownload: React.FC<PresetUploadDownloadProps> = ({
  documentId,
  onUpload,
}) => (
  <div style={{ display: 'flex' }}>
    <PresetUploadButton onUpload={onUpload} documentId={documentId} />
    <div style={{ marginLeft: 10 }}>
      <PresetDownloadButton documentId={documentId} />
    </div>
  </div>
);
