import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import React, { useState } from 'react';
import styled from 'styled-components';
import { RunQuery, useRunQuery } from '../../../generated/graphql';
import { Error } from '../../Utility/Error';
import { Loading } from '../../Utility/Loading';
import PrintPlannedProduct from './PrintPlannedProduct';

export interface ProductSelectProps {
  runId: string;
  back: () => void;
  timberSmartRunTypeId: string;
}

export type PlanLine = NonNullable<RunQuery['run']>['outfeedPlan']['lines'][0];

const ProductSelect: React.FC<ProductSelectProps> = ({ runId, back, timberSmartRunTypeId }) => {
  const [selectedProduct, setSelectedProduct] = useState<PlanLine | null>(null);
  const { data, loading, error } = useRunQuery({
    variables: {
      id: runId,
    },
  });

  if (loading) {
    return <Loading />;
  }
  if (error || !data) {
    return (
      <Error fullscreen errorText="Error fetching packs, please contact IT." />
    );
  }

  const outfeedPlanLines = data?.run?.outfeedPlan.lines;

  if (selectedProduct) {
    return (
      <PrintPlannedProduct
        plannedProduct={selectedProduct}
        runId={runId}
        back={() => setSelectedProduct(null)}
        timberSmartRunTypeId={timberSmartRunTypeId}
      />
    );
  }

  return (
    <ProductSelectStyle>
      <HeaderStyle>
        <Button
          className="back"
          variant="contained"
          color="error"
          onClick={back}
        >
          Back
        </Button>
        <div className="header-text">
          <Typography variant="h4" className="run">
            {runId}
          </Typography>
        </div>
      </HeaderStyle>

      <TableContainer className="table">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>Stock Code</TableCell>
              <TableCell style={{ textAlign: 'center' }}>Qty</TableCell>
              <TableCell>Unit Number</TableCell>
              <TableCell>Stacking Instructions</TableCell>
              <TableCell>Finished Product</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortProducts(outfeedPlanLines as PlanLine[]).map((row, i) => {
              return (
                <Row
                  row={row}
                  setSelectedProduct={setSelectedProduct}
                  key={i}
                />
              );
            }) || (
              <Typography style={{ textAlign: 'center' }} variant="h3">
                No products found. 😵
              </Typography>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </ProductSelectStyle>
  );
};

export default ProductSelect;

export interface RowProps {
  row: PlanLine;
  setSelectedProduct: (product: PlanLine) => void;
}

const Row: React.FC<RowProps> = ({ row, setSelectedProduct }) => {
  const linealCompleted = row.linealCompleted ?? 0;
  const setLength = row.wipProduct.setLength || 0;

  const qtyClass = () => {
    if (row.piecesPlanned) {
      if (row.piecesPlanned > linealCompleted / setLength) {
        return 'qty-in-progress';
      }
      if (row.piecesPlanned === linealCompleted / setLength) {
        return 'qty-complete';
      }
      if (row.piecesPlanned < linealCompleted / setLength) {
        return 'qty-excess';
      }
    } else if (row.linealPlanned) {
      if (row.linealPlanned > linealCompleted) {
        return 'qty-in-progress';
      }
      if (row.linealPlanned === linealCompleted) {
        return 'qty-complete';
      }
      if (row.linealPlanned < linealCompleted) {
        return 'qty-excess';
      }
    }
  };

  return (
    <TableRow
      key={row?.id}
      onClick={() => {
        setSelectedProduct(row);
      }}
      style={{ height: '4em' }}
    >
      <TableCell>
        {row.finishedProduct?.description ?? row.wipProduct.description}
      </TableCell>
      <TableCell>
        {row.finishedProduct?.stockCode ?? row.wipProduct.id}
      </TableCell>
      <TableCell>
        <div
          className={qtyClass()}
          style={{
            boxShadow: 'rgb(0 0 0 / 25%) 2px 3px 4px 0px',
            borderRadius: '20px',
            width: '8em',
            padding: '3px 0px',
            textAlign: 'center',
          }}
        >
          {qtyDisplayString(row, linealCompleted, setLength)}
        </div>
      </TableCell>
      <TableCell>{row.unitNumber}</TableCell>
      <TableCell>{row.packBuild}</TableCell>
      <TableCell
        style={{
          textAlign: 'center',
        }}
      >
        {row.finishedProduct && <DoneIcon />}
      </TableCell>
    </TableRow>
  );
};

export function qtyDisplayString(
  row: PlanLine,
  linealCompleted: number,
  setLength: number,
): string {
  if (!row.piecesPlanned && !row.linealPlanned) {
    return 'Recovery';
  }

  return row.piecesPlanned
    ? `${Math.round(linealCompleted / setLength)}/${row.piecesPlanned} PCE`
    : `${Math.round(linealCompleted)}/${row.linealPlanned} LM`;
}

// Sorts by description, then sorts 'Recovery' (0 lm or pce's planned) lines to
// the bottom
function sortProducts(products: PlanLine[]): PlanLine[] {
  const productsWithoutPlanned: PlanLine[] = [];
  const productsWithPlanned: PlanLine[] = [];

  for (const product of products) {
    if (product.piecesPlanned || product.linealPlanned) {
      productsWithPlanned.push(product);
    } else {
      productsWithoutPlanned.push(product);
    }
  }

  productsWithPlanned.sort((a, b) => {
    const aDesc = a.finishedProduct?.description ?? a.wipProduct.description;
    const bDesc = b.finishedProduct?.description ?? b.wipProduct.description;

    if (aDesc < bDesc) {
      return -1;
    }

    if (aDesc > bDesc) {
      return 1;
    }
    return 0;
  });

  return productsWithPlanned.concat(productsWithoutPlanned);
}

export const HeaderStyle = styled.div`
  display: flex;
  flex-direction: row;
  margin: 15px 15px 25px 15px;
  .back {
    width: 120px;
  }

  .header-text {
    display: flex;
    width: 100%;
    place-content: center;
    .title {
      margin-right: 25px;
    }
    .run {
      font-weight: 600;
    }
  }
`;

const ProductSelectStyle = styled.div`
  margin-left: 1em;
  margin-right: 1em;

  animation: fadeIn 0.5s;

  .qty-in-progress {
    background-color: #e3dfdd;
  }

  .qty-complete {
    background-color: #638680;
  }

  .qty-excess {
    background-color: #d35f5f;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .table {
    height: calc(100vh - 16rem);
    overflow: auto;
  }
`;
