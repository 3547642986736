const DateTransformer = {
  parseValue: (time: Date) => new Date(time),
  serialize: (time: Date) => time.toISOString(),
};

const transformers = {
  SomeGraphTypeName: {
    dateModified: DateTransformer,
  },
};

export default transformers;
