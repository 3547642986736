import { LabelTemplateField } from '../types';

export function initialCustomFieldValues(fields: LabelTemplateField[]) {
  const obj: Record<string, string> = {};
  for (const field of fields) {
    if (field.internalName == null) continue;
    obj[field.internalName] = '';
  }
  return obj;
}

/**
 * Formats a number in a similar way to `.toFixed`, but if the result would
 * end in only zeros, hide them. E.g. rather than `showDp(3, 4)` being '3.0000',
 * it would be '3'.
 */
export function showDp(num: number, dp: number) {
  const mul = Math.pow(10, dp);
  return Math.round(num * mul) / mul;
}
