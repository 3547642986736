import React from 'react';
import styled from 'styled-components';

export interface ErrorProps {
  fullscreen?: boolean;
  errorText?: string;
}
export const Error: React.FC<ErrorProps> = ({ fullscreen, errorText }) => (
  <ErrorStyling fullscreen={fullscreen}>
    <div>{errorText}</div>
  </ErrorStyling>
);

const ErrorStyling = styled.div<ErrorProps>`
  display: flex;
  justify-content: center;
  margin-top: 100px;

  ${(props) =>
    props.fullscreen &&
    `
    width: 100vw;
    height: 100vh;
    align-items: center;
  `}
`;
