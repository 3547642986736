import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { TemplateParseRow } from '../utils/bulk-print';

export interface BulkPrintTableProps {
  headers: string[];
  rows: TemplateParseRow[];
}

export function BulkPrintTable({ headers, rows }: BulkPrintTableProps) {
  return (
    <Table style={{ margin: '2rem 0' }}>
      <TableHead>
        <TableRow>
          <TableCell>
            <strong>Quantity</strong>
          </TableCell>
          {headers.map((header, idx) => (
            <TableCell key={idx}>{header}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, idx) => (
          <TableRow key={idx}>
            <TableCell>{row.quantity}</TableCell>
            {headers.map((header, idx) => {
              const value = row.fields.get(header) ?? '';
              return (
                <TableCell key={idx}>
                  {value === '' ? (
                    <span style={{ color: 'red' }}>Empty</span>
                  ) : (
                    value
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
