import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createTransformerLink } from 'apollo-client-transform';
import { SentryLink } from 'apollo-link-sentry';
import { createUploadLink } from 'apollo-upload-client';
import introspectionResult from '../../generated/introspection-result';

import { env } from '../../runtime-environment';
import transformers from './transformers';

const transformerLink = createTransformerLink(transformers);
const httpLink = createUploadLink({ uri: env.serverUri });

const authLink = setContext(async (_, { headers }) => ({
  headers: {
    ...headers,
    // Requests for this specific app can be found in Apollo Studio
    'apollographql-client-name': 'universal-print',
    'apollographql-client-version': env.version || 'local',
    // "Authorization" on the VPN
    'x-client-app': 'universal-print',
  },
}));

const cache = new InMemoryCache({
  possibleTypes: introspectionResult.possibleTypes,
  typePolicies: {},
});

const sentryLink = new SentryLink({
  uri: env.serverUri,
  setTransaction: true,
  setFingerprint: true,
  attachBreadcrumbs: {},
});

export default new ApolloClient({
  link: ApolloLink.from([
    authLink,
    transformerLink as any,
    sentryLink,
    httpLink,
  ]),
  cache,
});
