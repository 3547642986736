import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import BackspaceIcon from '@mui/icons-material/Backspace';

export interface NumpadProps {
  value: string;
  setValue: (x: string) => void;
}

const Numpad: React.FC<NumpadProps> = ({ value, setValue }) => {
  const numArray = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const disabled = value === '';

  const onDigit = (num: number) => {
    setValue(value + num.toString());
  };

  const onBackspace = () => {
    setValue(value.slice(0, value.length - 1));
  };

  return (
    <KeyboardStyle>
      {numArray.map((num) => (
        <Button
          key={num}
          variant="contained"
          color="secondary"
          onClick={() => onDigit(num)}
        >
          {num}
        </Button>
      ))}
      <Button
        color="secondary"
        variant="contained"
        endIcon={<BackspaceIcon />}
        disabled={disabled}
        onClick={onBackspace}
      />
      <Button
        color="secondary"
        variant="contained"
        disabled={disabled}
        onClick={() => onDigit(0)}
      >
        0
      </Button>
      <Button
        color="secondary"
        variant="contained"
        disabled={disabled}
        onClick={() => setValue('')}
      >
        Clear
      </Button>
    </KeyboardStyle>
  );
};

const KeyboardStyle = styled.div`
  display: grid;
  width: 300px;
  grid-gap: 12px;
  grid-template-columns: repeat(3, 1fr);
  button {
    height: 70px;
    font-weight: 600;
    font-size: large;
    color: #375954;
  }

  .MuiButton-endIcon {
    margin: 0px;
  }
`;

export default Numpad;
