import React from 'react';
import { ErrorMessage } from '../Utility/ErrorMessage';
import DumbDocumentList from './DumbDocumentList';
import { useAppDispatch } from '../../store';
import { AppActions, Step } from '../../store/reducer';
import { useLabelTemplatesQuery } from '../../generated/graphql';

const DocumentList: React.FC = () => {
  const dispatch = useAppDispatch();

  const selectDocument = (id: string, type: 'preset' | 'custom') => {
    dispatch(AppActions.setPrintDocumentId({ id }));
    dispatch(
      AppActions.setStep({
        step: type === 'custom' ? Step.CUSTOM_PRINT : Step.PRESET_SELECTION,
        pushState: true,
      }),
    );
  };

  const { data, loading, error } = useLabelTemplatesQuery();

  if (loading) return <div>Loading...</div>;
  if (error || !data?.labelTemplates) return <ErrorMessage />;

  return (
    <DumbDocumentList
      documents={data.labelTemplates}
      selectDocument={selectDocument}
    />
  );
};

export default DocumentList;
