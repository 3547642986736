import dateFormat from 'dateformat';
import * as XLSX from 'xlsx';
import { LabelTemplateField } from '../types';

const SHEET_NAME = 'Data';
const QUANTITY_HEADER = 'Quantity';

export function createExcelTemplate(fields: string[]) {
  const headings = [QUANTITY_HEADER, ...fields];
  const worksheet = XLSX.utils.aoa_to_sheet([headings]);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, SHEET_NAME);
  XLSX.writeFile(workbook, 'Bulk Print.xlsx');
}

export interface TemplateParseRow {
  quantity: number;
  fields: Map<string, string>;
}

export type TemplateParseResult =
  | {
      type: 'success';
      rows: TemplateParseRow[];
    }
  | { type: 'error'; errors: string[] };

export async function parseExcelTemplate(
  file: File,
  fields: LabelTemplateField[],
): Promise<TemplateParseResult> {
  const buffer = await file.arrayBuffer();
  const workbook = XLSX.read(buffer, { cellDates: true });
  const sheet = workbook.Sheets[SHEET_NAME];

  const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 }) as unknown[][];

  if (rows.length === 0) {
    return {
      type: 'error',
      errors: ['No data found'],
    };
  }

  const errors: string[] = [];

  const headerNames = new Set([...rows[0]]);
  for (const field of [...fields.map((x) => x.name), QUANTITY_HEADER]) {
    if (headerNames.has(field)) {
      headerNames.delete(field);
    } else {
      errors.push('Missing column: ' + field);
    }
  }
  for (const field of headerNames) {
    errors.push('Extra field: ' + field);
  }

  if (errors.length > 0) {
    return {
      type: 'error',
      errors,
    };
  }

  const quantityIndex = rows[0].findIndex((x) => x === QUANTITY_HEADER);
  const headerIndices = new Map(
    rows[0]
      .map((header, idx) => [idx, String(header)] as const)
      .filter(([idx]) => idx !== quantityIndex),
  );

  const result = rows.slice(1).flatMap((row, index) => {
    if (row.length === 0) {
      return [];
    }

    const quantity = Number(row[quantityIndex]);
    if (isNaN(quantity)) {
      errors.push(`Row ${index + 2}: Quantity is not a number`);
      return [];
    }

    return [
      {
        quantity,
        fields: new Map(
          row.map((cell, idx) => [
            headerIndices.get(idx)!,
            cell instanceof Date
              ? dateFormat(cell, 'dd/mm/yyyy')
              : String(cell ?? ''),
          ]),
        ),
      },
    ];
  });

  if (errors.length > 0) {
    return {
      type: 'error',
      errors,
    };
  }

  return {
    type: 'success',
    rows: result,
  };
}
