import { TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import React, { useState } from 'react';
import styled from 'styled-components';
import { PackLog, useRecentPackLogsQuery } from '../generated/graphql';
import { RecentPackLine } from './RecentPackLine';
import { Error } from './Utility/Error';

export interface RecentPacksProps {
  workstationId: string;
}

export const RecentPacks: React.FC<RecentPacksProps> = ({ workstationId }) => {
  const [packNumber, setPackNumber] = useState<string | null>(null);

  const { loading, data, error } = useRecentPackLogsQuery({
    pollInterval: 300_000,
    variables: {
      workstationId,
      searchQuery: packNumber,
      take: 75,
    },
  });

  if (error) {
    return <Error />;
  }

  return (
    <RecentPacksStyle>
      <div className="search">
        <TextField
          id="outlined-basic"
          variant="outlined"
          autoComplete="off"
          label={'Search Pack Number'}
          defaultValue={packNumber}
          onChange={(e) => {
            const { value } = e.target;
            setPackNumber(value);
          }}
          value={packNumber}
        />
        <Button
          variant="contained"
          disableElevation
          disabled={!packNumber}
          onClick={() => setPackNumber('')}
        >
          Clear
        </Button>
      </div>
      {data && data.packLogs.length > 0 ? (
        <List className="packs">
          {data.packLogs.map((p) => (
            <RecentPackLine key={p!.id} recentPack={p as PackLog} />
          ))}
        </List>
      ) : (
        !loading && (
          <Alert severity="warning" className="no-results">
            Could not find any packs
          </Alert>
        )
      )}
    </RecentPacksStyle>
  );
};

const RecentPacksStyle = styled.div`
  .search {
    display: inline-flex;
    margin-bottom: 10px;
  }
  .no-results {
    align-items: center;
    font-size: large;
  }
  .packs {
    height: 510px;
    overflow-y: auto;
  }
`;
