import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { AppActions } from '../../store/reducer';
import { ErrorMessage } from '../Utility/ErrorMessage';
import { PrinterSelector } from './PrinterSelector';
import { PrintFooter } from './PrintFooter';
import { useDefaultPrinter } from '../../utils/defaultPrinters';
import { CustomFieldsDisplay } from '../CustomFieldsDisplay';
import { initialCustomFieldValues } from '../../utils/helpers';
import styled from 'styled-components';
import Container from '@mui/material/Container';
import { useLabelTemplateQuery } from '../../generated/graphql';
import { BulkPrintModal } from '../BulkPrintModal';

export const CustomPrinter: React.FC = () => {
  const setDataValue = (fieldName: string, value: string) => {
    dispatch(
      AppActions.setPrintData({
        fields: {
          ...data,
          [fieldName]: value,
        },
      }),
    );
  };

  const dispatch = useAppDispatch();
  const documentId = useAppSelector((s) => s.printDocumentId)!;
  const data = useAppSelector((s) => s.printData);

  const {
    data: response,
    loading,
    error,
  } = useLabelTemplateQuery({
    variables: { id: documentId },
  });

  const template = response?.labelTemplate ?? undefined;

  const [printer, printers, setPrinter] = useDefaultPrinter(template);

  const customFields = template?.fields.filter((f) => f.internalName != null);
  const documentName = template?.name ?? '';

  const content = (() => {
    if (loading) return <div>Loading...</div>;
    if (error) return <ErrorMessage />;
    if (response) {
      return (
        <>
          <CustomFieldsDisplay
            fields={customFields!}
            values={data}
            setValue={setDataValue}
          />

          <BulkPrintModal
            documentName={documentName}
            initialPrinter={printer}
            fields={template?.fields ?? []}
          />
        </>
      );
    }
    return <div />;
  })();

  const presetData = customFields
    ? [{ ...initialCustomFieldValues(customFields), ...data }]
    : null;

  return (
    <CustomPrinterStyle>
      <Container className="Container">
        <div>
          <h2 className="DocName">Print Custom Labels - {documentName}</h2>
          <PrinterSelector
            printer={printer}
            printers={printers}
            setPrinter={setPrinter}
            style={{ marginBottom: 20 }}
          />
          {content}
        </div>
      </Container>

      <PrintFooter
        documentName={documentName}
        printerName={printer}
        data={presetData ?? []}
      />
    </CustomPrinterStyle>
  );
};

const CustomPrinterStyle = styled.div`
  flex-grow: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  .Container {
    margin: 20px auto 0 auto;
    display: flex;
    overflow-y: auto;
    flex-grow: 1;

    .DocName {
      margin-bottom: 20px;
    }
  }
`;
