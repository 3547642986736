import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React, { useState } from 'react';
import styled from 'styled-components';
import Logo from '../assets/companyLogo.png';
import {
  PackWorkstation,
  useFindWorkstationByNameQuery,
} from '../generated/graphql';
import { usePrinters } from '../utils/defaultPrinters';
import { WorkstationPrinterSelector } from './PrinterPresets/WorkstationPrinterSelector';
import { RecentPacks } from './RecentPacks';
import { Error } from './Utility/Error';
import { Loading } from './Utility/Loading';
import { RunOutfeed } from './Workstations/Planned/RunOutfeed';

export const NavMenu: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);

  let urlInfo = window.location.pathname.split('/').pop() as string;
  if (urlInfo === 'print') {
    const workstationName = window.location.pathname.split('/');
    urlInfo = workstationName[2];
  }

  const {
    data: workstationData,
    loading: workstationLoading,
    error: workstationError,
  } = useFindWorkstationByNameQuery({
    variables: { workstation: urlInfo },
  });

  const printers = usePrinters();

  if (workstationLoading) return <Loading />;
  if (workstationError || !workstationData?.packWorkstationByName) {
    return <Error />;
  }

  const workstation = workstationData.packWorkstationByName;

  if (urlInfo.length === 0) {
    return null;
  }

  const headerName =
    workstation.timberSmartRunTypeId != null
      ? 'Run Outfeed'
      : 'Recently Printed';

  return (
    <NavStyle>
      <div>
        <img className="Logo" src={Logo} alt="Logo" />
      </div>
      <div className="NavInfo">
        {urlInfo && (
          <div className="WorkstationNameNav">
            <span className="label"> Workstation:</span>
            <span className="value">{urlInfo}</span>
          </div>
        )}
        <WorkstationPrinterSelector
          printers={printers ?? []}
          workstation={workstation as PackWorkstation}
        />
        <Button
          aria-label="show 4 new mails"
          color="primary"
          size="small"
          className="print-button"
          variant="contained"
          onClick={() => setOpenModal(true)}
        >
          {headerName}
        </Button>
      </div>

      <Dialog
        fullScreen
        maxWidth="md"
        onClose={() => setOpenModal(false)}
        open={openModal}
      >
        <DialogContent>
          <NavContentsStyle>
            <div
              className="header"
              style={{ color: '#375954', fontWeight: 600 }}
            >
              {headerName}
              <Button
                aria-label="close"
                color="error"
                className="close"
                variant="contained"
                size={'large'}
                onClick={() => setOpenModal(false)}
              >
                Close
              </Button>
            </div>

            {workstation.timberSmartRunTypeId == null ? (
              <RecentPacks workstationId={workstation.id} />
            ) : (
              <RunOutfeed runTypeId={workstation.timberSmartRunTypeId} />
            )}
          </NavContentsStyle>
        </DialogContent>
      </Dialog>
    </NavStyle>
  );
};

const NavContentsStyle = styled.div`
  .header {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-size: x-large;
    margin-bottom: 20px;
  }
`;

const NavStyle = styled.div`
  margin: 10px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  border-bottom: 1px solid #d3d3d3;

  .print-button {
    margin: 0px 20px;
    background: #375954;
  }

  .Logo {
    width: 250px;
  }

  .DialogStyle {
    background: red;
  }

  .WorkstationNameNav {
    margin: 0px 40px;
    display: flex;
    flex-direction: column;
    .label {
      transform: translateY(-12px);
    }
    .value {
      font-weight: bold;
      color: #375954;
      font-size: x-large;
      font-weight: 600;
    }
  }
  .PrinterNameNav {
    margin-left: 40px;
    margin-right: 40px;
    .PrinterName {
      margin-left: 10px;
      font-weight: bold;
    }
  }

  .MuiInputBase-root {
    font-weight: 600;
    color: #375954;
    font-size: large;
  }

  .NavInfo {
    display: flex;
    margin-top: 1em;
    height: 50px;
  }
`;
