import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
  JSON: unknown;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

/**
 * Stores a number which changes when used, intended for generating sequences or
 * unique identifiers.
 */
export type Counter = {
  __typename?: 'Counter';
  /** Unique identifier for the counter. Can be any string. */
  id: Scalars['ID'];
  /**
   * The amount the `value` of the counter will change when used once. May be
   * negative.
   */
  updateChange: Scalars['Int'];
  /** The counter's current value. */
  value: Scalars['Int'];
};

export type CounterCreateInput = {
  /** Unique identifier for fetching the `Counter` once created. Can be any string. */
  id: Scalars['ID'];
  /**
   * The initial value of the counter. Is the value that will be returned when
   * first running the `useCounter` mutation for this `Counter` ID.
   */
  initialValue: Scalars['Int'];
  /**
   * If `true`, will replace any existing counter. If `false` or unspecified,
   * counter creation would fail if the ID was already registered.
   */
  reinitialize?: Maybe<Scalars['Boolean']>;
  /**
   * The amount the `value` of the counter will change when used once. May be
   * negative.
   */
  updateChange: Scalars['Int'];
};



export type Debtor = {
  __typename?: 'Debtor';
  id: Scalars['ID'];
};

export type DebtorInvoice = {
  __typename?: 'DebtorInvoice';
  id: Scalars['ID'];
};

export type DebtorInvoiceLine = {
  __typename?: 'DebtorInvoiceLine';
  id: Scalars['ID'];
};

/** Information about a file that has been deleted. */
export type DeletedFile = {
  __typename?: 'DeletedFile';
  deletionReason: FileDeletionReason;
};

/** A durable file containing human-readable information. Can be printed. */
export type Document = {
  __typename?: 'Document';
  /** Unique ID. */
  id: Scalars['ID'];
  /** URL for displaying the document, valid for approximately 30 minutes */
  url: Scalars['String'];
};

export type DocumentGenerateCommandInput = {
  /**
   * The data to apply to the `DocumentTemplate` to generate a document.
   * If the `DocumentTemplate` provides default/example data, this is optional.
   */
  data?: Maybe<Scalars['JSON']>;
  /** The `DocumentTemplate` that should be used, for example 'examples.hello'. */
  templateId: Scalars['ID'];
  /**
   * Location to upload the generated document to. Should be an Azure Blob Storage
   * URL (with SAS token or otherwise authenticated), typically obtained via use of
   * `uploadHandlesGet`.
   */
  uploadUrl: Scalars['String'];
};

export type DocumentGenerateInput = {
  /** List of commands for generating documents. */
  commands: Array<DocumentGenerateCommandInput>;
};

export type DocumentGeneratePayload = {
  __typename?: 'DocumentGeneratePayload';
  /**
   * Always an empty array
   * @deprecated Always an empty array, so no longer serves a purpose.
   */
  documents: Array<Document>;
  message: Scalars['String'];
};

export type DocumentPrintCommandInput = {
  /** Number of copies to print. Cannot be negative. Defaults to 1. */
  copies?: Maybe<Scalars['Int']>;
  /** ID of the printer to use. */
  printer: Scalars['String'];
  /** Location to fetch the document from, before printing. Must be a PDF file. */
  url: Scalars['String'];
};

export type DocumentPrintInput = {
  /** List of commands for printing. */
  commands: Array<DocumentPrintCommandInput>;
};

export type DocumentPrintPayload = {
  __typename?: 'DocumentPrintPayload';
  message: Scalars['String'];
};

export type DuplicatePackError = {
  __typename?: 'DuplicatePackError';
  /** Human-readable error message. */
  message: Scalars['String'];
};

export enum EmailAttachmentContentKind {
  /** Treat contents as the attachment data itself, encoded as base 64. */
  Base64 = 'BASE64',
  /** Treat contents as a URL to download the attachment data from. */
  DownloadUrl = 'DOWNLOAD_URL'
}

export type EmailSendAttachmentInput = {
  /** The raw content of the attachment. */
  content: Scalars['String'];
  /** Describes how to interpret `content`. */
  contentKind: EmailAttachmentContentKind;
  /** The content type of the attachment, e.g. `application/pdf`. */
  contentType: Scalars['String'];
  /** The filename the attachment should have. */
  filename: Scalars['String'];
};

export type EmailSendEmailInput = {
  /** List of attachments to send. */
  attachments: Array<EmailSendAttachmentInput>;
  /** List of BCC email recipients. If not provided, equivalent to the empty list. */
  bcc?: Maybe<Array<Scalars['String']>>;
  /** List of CC email recipients. If not provided, equivalent to the empty list. */
  cc?: Maybe<Array<Scalars['String']>>;
  /**
   * The email address acting as the sender. Must be from a domain configured with
   * SendGrid. If not specified, the default from address will be used.
   */
  from?: Maybe<Scalars['String']>;
  /** Use to define the header, such as setting Importance levels. */
  headers?: Maybe<Scalars['JSON']>;
  /** Only use if `templateId` is null. */
  html?: Maybe<Scalars['String']>;
  /** Only use if `templateId` is null. */
  subject?: Maybe<Scalars['String']>;
  /** Only use if `templateId` is null. */
  templateData?: Maybe<Scalars['JSON']>;
  /** The template id to use for the data (see `templates.staging-production.json`). */
  templateId?: Maybe<Scalars['ID']>;
  /** Only use if `templateId` is null. */
  text?: Maybe<Scalars['String']>;
  /** List of email recipients. */
  to: Array<Scalars['String']>;
};

export type EmailSendInput = {
  emails: Array<EmailSendEmailInput>;
};

export type EmailSendPayload = {
  __typename?: 'EmailSendPayload';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

/** Email template that can be printed. */
export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** A handle to a file in Azure Blob Storage. */
export type File = {
  __typename?: 'File';
  /** Mime-type of the file, e.g. "application/pdf". */
  contentType: Scalars['String'];
  /** When, if at all, the file will expire or did expire. */
  expireAt?: Maybe<Scalars['DateTime']>;
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Data relating to the file, different depending on the file's status */
  info: FileInfo;
  /** Name of the file, if specified when creating an upload handle. */
  name?: Maybe<Scalars['String']>;
};

export enum FileDeletionReason {
  /** File was deleted automatically because it expired. */
  Expired = 'EXPIRED'
}

export type FileInfo = DeletedFile | PresentFile;

export enum FilePermissions {
  /** The default. Files can only be read. */
  Read = 'READ',
  /**
   * Files can be updated after creation - an upload url can always be
   * requested. However unlike the `accessUrl` field, doing so requires
   * the admin scope.
   */
  ReadUpdate = 'READ_UPDATE'
}

export type Fulfilment = {
  __typename?: 'Fulfilment';
  id: Scalars['ID'];
};

export type FulfilmentLine = {
  __typename?: 'FulfilmentLine';
  id: Scalars['ID'];
};

export type FulfilmentLinePack = {
  __typename?: 'FulfilmentLinePack';
  id: Scalars['ID'];
};

export type IncompatiblePackError = {
  __typename?: 'IncompatiblePackError';
  /** Human-readable error message. */
  message: Scalars['String'];
};

/** Describes a physical transfer of a collection of physical products 'StockItem's from one location to another. */
export type InventoryTransfer = {
  __typename?: 'InventoryTransfer';
  /** Creating user. */
  createdBy: User;
  /** The date of creation. */
  dateCreated: Scalars['Date'];
  /** The date of receival. */
  dateReceived?: Maybe<Scalars['Date']>;
  /** The destination of the stock. */
  destination: StockLocation;
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Where stock is drawn from. */
  origin: StockLocation;
  /** Amount of packs being transferred. */
  packCount: Scalars['Int'];
  /** List of packs to be moved. */
  packs: Array<InventoryTransferPack>;
  /** Reference. */
  reference: Scalars['String'];
  /** Only for `InventoryTransferType` `TRUCK`, specifies trucking company. */
  shippingProvider?: Maybe<ShippingProvider>;
  /** The shipment status. */
  status: InventoryTransferStatus;
  /** The total weight in kilograms. */
  totalKilograms: Scalars['Float'];
  /** The M3. */
  totalMetersCubed: Scalars['Float'];
  /** The method of transportation. */
  type: InventoryTransferType;
};

export type InventoryTransferAddPackInput = {
  /** The `InventoryTransfer` this pack is being added to. */
  inventoryTransferId: Scalars['ID'];
  /** M3 of pack. */
  metresCubed: Scalars['Float'];
  /** The unique ID of the pack. */
  packNumber: Scalars['String'];
  /** The quantity of the given `StockItem` contained in the pack being added. */
  quantity: Scalars['Float'];
  /** Stockcode ID of pack contents. */
  stockCode: Scalars['String'];
  /** Weight of pack. */
  weightKilograms: Scalars['Float'];
};

export type InventoryTransferAddPackPayload = {
  __typename?: 'InventoryTransferAddPackPayload';
  /** The `InventoryTransfer` the pack was added to. */
  inventoryTransfer: InventoryTransfer;
  /** The `InventoryTransferPack` added. */
  pack: InventoryTransferPack;
};

export type InventoryTransferCreateInput = {
  /** The location of `InventoryTransfer` destination. */
  destinationStockLocationId: Scalars['ID'];
  /** The method of transfer. */
  inventoryTransferType: InventoryTransferType;
  /** The location of `InventoryTransfer` origin. */
  originStockLocationId: Scalars['ID'];
  /** Only for `InventoryTransferType` = `TRUCK`. Specifies trucking company */
  shippingProviderId?: Maybe<Scalars['ID']>;
};

export type InventoryTransferCreatePayload = {
  __typename?: 'InventoryTransferCreatePayload';
  /** The `InventoryTransfer` that has been created. */
  inventoryTransfer: InventoryTransfer;
};

export type InventoryTransferDeleteInput = {
  /** The `InventoryTransfer` being soft deleted. */
  inventoryTransferId: Scalars['ID'];
};

export type InventoryTransferDeletePayload = {
  __typename?: 'InventoryTransferDeletePayload';
  /** The `InventoryTransfer` that has been deleted. */
  inventoryTransfer: InventoryTransfer;
};

export type InventoryTransferGiveUpInput = {
  /** The `InventoryTransfer` to give up. */
  inventoryTransferId: Scalars['ID'];
};

export type InventoryTransferGiveUpPayload = {
  __typename?: 'InventoryTransferGiveUpPayload';
  /** The `InventoryTransfer` that has just been given up. */
  inventoryTransfer: InventoryTransfer;
};

export type InventoryTransferInput = {
  __typename?: 'InventoryTransferInput';
  destinationLocationId: Scalars['ID'];
  fromLocationId: Scalars['ID'];
  inventoryTransferId: Scalars['ID'];
  packs?: Maybe<Array<InventoryTransferPack>>;
  reference?: Maybe<Scalars['String']>;
};

/** A (typically) large number of items of a particular `StockItem` packed efficiently so as to facilitate transport. */
export type InventoryTransferPack = {
  __typename?: 'InventoryTransferPack';
  /** Unique identifier. */
  id: Scalars['ID'];
  /** The `InventoryTransfer` this pack belongs to. */
  inventoryTransfer: InventoryTransfer;
  /** The total M3 of the pack. */
  metresCubed: Scalars['Float'];
  /** The pack number. */
  packNumber: Scalars['String'];
  /** Total number of this `InventoryTransferPack`. */
  quantity: Scalars['Float'];
  /** The `StockItem` contained within. */
  stockItem: StockItem;
  /** The total weight of the pack in kilograms. */
  weightKilograms: Scalars['Float'];
};

export type InventoryTransferReceiveInput = {
  /** The `InventoryTransfer` being received. */
  inventoryTransferId: Scalars['ID'];
};

export type InventoryTransferReceivePayload = {
  __typename?: 'InventoryTransferReceivePayload';
  /** The `InventoryTransfer` that was received. */
  inventoryTransfer: InventoryTransfer;
};

export type InventoryTransferRedirectInput = {
  /** The new destination location. If null, destination remains the same. */
  destinationStockLocationId?: Maybe<Scalars['ID']>;
  /** The `InventoryTransfer` to redirect. */
  inventoryTransferId: Scalars['ID'];
  /** The new origin location. If null, origin remains the same. */
  originStockLocationId?: Maybe<Scalars['ID']>;
};

export type InventoryTransferRedirectPayload = {
  __typename?: 'InventoryTransferRedirectPayload';
  /** Error message, if any. */
  error?: Maybe<Scalars['String']>;
  /** The `InventoryTransfer` that has just been redirected. */
  inventoryTransfer?: Maybe<InventoryTransfer>;
};

export type InventoryTransferRemovePackInput = {
  /** The `InventoryTransferPack` being removed from the `InventoryTransfer`. */
  packId: Scalars['ID'];
};

export type InventoryTransferRemovePackPayload = {
  __typename?: 'InventoryTransferRemovePackPayload';
  /** The `InventoryTransfer` the `InventoryTransferPack` was removed from */
  inventoryTransfer: InventoryTransfer;
};

export type InventoryTransferStartTransitInput = {
  /** The `InventoryTransfer` who's status is being set to `IN_TRANSIT`. */
  inventoryTransferId: Scalars['ID'];
  /** Reference for quick recognition of `InventoryTransfer` by office staff. */
  reference: Scalars['String'];
};

export type InventoryTransferStartTransitPayload = {
  __typename?: 'InventoryTransferStartTransitPayload';
  /** The `InventoryTransfer` describing the packs that have just left the origin `StockLocation`. */
  inventoryTransfer: InventoryTransfer;
};

/** The possible statuses of an `InventoryTransfer` in relation to its desired location. */
export enum InventoryTransferStatus {
  /** Status once received at the desired location */
  Complete = 'COMPLETE',
  /** Status upon creation at the location of origin */
  Creating = 'CREATING',
  /** Status on soft deletion. */
  Deleted = 'DELETED',
  /** Status when a user gives up on a transfer */
  Error = 'ERROR',
  /**
   * Status when error occurs in EXO update when receiving a transfer. The user may
   * safely reattempt `inventoryTransferReceive` again on a shipment with this
   * status.
   */
  ErrorReceiveTransfer = 'ERROR_RECEIVE_TRANSFER',
  /**
   * Status when error occurs in EXO update when starting a transfer. The user may
   * try to perform the `inventoryTransferStartTransit` operation again if they
   * wish. If a transfer is in this state, stock levels are not in an inconsistent
   * state - nothing has happened in EXO due to the transfer.
   */
  ErrorStartTransit = 'ERROR_START_TRANSIT',
  /**
   * Status when error occurs in EXO update when undoing a transfer. The user may
   * safely reattempt `inventoryTransferUndo` again on a shipment with this status.
   */
  ErrorUndoTransit = 'ERROR_UNDO_TRANSIT',
  /** Status during transit, on the way to the desired location */
  InTransit = 'IN_TRANSIT'
}

/** The method of transport for an `InventoryTransfer`. */
export enum InventoryTransferType {
  /** `InventoryTransfer` being sent in a shipping `CONTAINER`. */
  Container = 'CONTAINER',
  /**
   * `InventoryTransfer` is to appear immediately in the destination location,
   * without need for receipting.
   */
  Instant = 'INSTANT',
  /** `InventoryTransfer` being sent by `TRUCK`. */
  Truck = 'TRUCK'
}

export type InventoryTransferUndoInput = {
  /** The `InventoryTransfer` being reverted from status `COMPLETE` back to `IN_TRANSIT`, in case of a user error. */
  inventoryTransferId: Scalars['ID'];
};

export type InventoryTransferUndoPayload = {
  __typename?: 'InventoryTransferUndoPayload';
  /** The `InventoryTransfer` that was undone. */
  inventoryTransfer: InventoryTransfer;
};


export type Kiln = {
  __typename?: 'Kiln';
  id: Scalars['ID'];
};

export type KilnCharge = {
  __typename?: 'KilnCharge';
  chargeNumber: Scalars['String'];
  id: Scalars['ID'];
};

export type KilnChargeItem = {
  __typename?: 'KilnChargeItem';
  id: Scalars['ID'];
};

export type LabelPrintCommandInput = {
  /**
   * The data to apply to the `DocumentTemplate` to generate a document.
   * If the `DocumentTemplate` provides default/example data, this is optional.
   */
  data: Array<LabelTemplateFieldData>;
  /** The number of copies to print. Must be nonnegative. */
  printQuantity: Scalars['Int'];
  /** The printer to print to. */
  printerId: Scalars['ID'];
  /** The `LabelTemplate` that should be used. For example 'Porta-FSC'. */
  templateId: Scalars['ID'];
};

export type LabelPrintInput = {
  /** List of commands for printing labels. */
  commands: Array<LabelPrintCommandInput>;
};

export type LabelPrintPayload = {
  __typename?: 'LabelPrintPayload';
  message: Scalars['String'];
};

/** Describes a template that can be used to make labels of a particular kind. */
export type LabelTemplate = {
  __typename?: 'LabelTemplate';
  /** Free text, way of loosely categorizing templates (group by category). */
  category?: Maybe<Scalars['String']>;
  /**
   * The printer that should be suggested automatically if printing labels with
   * this template.
   */
  defaultPrinter?: Maybe<Scalars['String']>;
  /** Fields for customizing generated labels. */
  fields: Array<LabelTemplateField>;
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Name of the template. */
  name: Scalars['String'];
  /**
   * Preset document, if any, in XLSX format, as a base64 string. Can use `age`
   * in the same was as in the `presets` field.
   */
  presetDocument?: Maybe<Scalars['String']>;
  /** How presets should be handled, or whether allowed at all. */
  presetKind: LabelTemplatePresetKind;
  /**
   * Preset data, empty array if none. Can use `age` to get previous versions.
   *   - `age = 0`: current version
   *   - `age = 1`: version before current
   *   - etc.
   */
  presets: Array<Array<Scalars['String']>>;
};


/** Describes a template that can be used to make labels of a particular kind. */
export type LabelTemplatePresetDocumentArgs = {
  age?: Maybe<Scalars['Int']>;
};


/** Describes a template that can be used to make labels of a particular kind. */
export type LabelTemplatePresetsArgs = {
  age?: Maybe<Scalars['Int']>;
};

/** A point of customization for labels generated with a `LabelTemplate`. */
export type LabelTemplateField = {
  __typename?: 'LabelTemplateField';
  /** For multiline fields, how many lines may typically be used. */
  defaultLineCount?: Maybe<Scalars['Int']>;
  /** Comment or example input to clarify the purpose of the field. */
  extraInfo: Scalars['String'];
  /** Null if the field is there for clarity in presets, and not for the label. */
  internalName?: Maybe<Scalars['String']>;
  /** Guide as to how the field should be entered. */
  kind: LabelTemplateFieldKind;
  /** User-friendly field name. */
  name: Scalars['String'];
  /** Whether this field should be provided by a preset, or entered manually. */
  preset: Scalars['Boolean'];
};

export type LabelTemplateFieldData = {
  field: Scalars['String'];
  value: Scalars['String'];
};

export enum LabelTemplateFieldKind {
  /** The field accepts multiline text. */
  Multiline = 'MULTILINE',
  /** The field should be given normal text. */
  Text = 'TEXT'
}

export enum LabelTemplatePresetKind {
  /** Can use presets, but with multi-select printing. */
  Multi = 'MULTI',
  /** Cannot use presets for this template */
  None = 'NONE',
  /** Can use presets, but with single-select printing. */
  Single = 'SINGLE'
}

export type LabelTemplateUploadPresetsError = LabelTemplateUploadPresetsInvalidDataError | LabelTemplateUploadPresetsNotFoundError;

export type LabelTemplateUploadPresetsInput = {
  /** The label template to upload presets to. */
  labelTemplateId: Scalars['ID'];
  /** The presets file, in XLSX format. */
  presets: Scalars['Upload'];
};

export type LabelTemplateUploadPresetsInvalidDataError = UserError & {
  __typename?: 'LabelTemplateUploadPresetsInvalidDataError';
  message: Scalars['String'];
};

export type LabelTemplateUploadPresetsNotFoundError = UserError & {
  __typename?: 'LabelTemplateUploadPresetsNotFoundError';
  message: Scalars['String'];
};

export type LabelTemplateUploadPresetsPayload = {
  __typename?: 'LabelTemplateUploadPresetsPayload';
  userErrors: Array<LabelTemplateUploadPresetsError>;
};

/** Physical locations. */
export type Location = {
  __typename?: 'Location';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Create a counter with the given initial data. */
  counterCreate: Scalars['Boolean'];
  /**
   * Fetches the current counter information and returns it, and then updates the
   * counter as appropriate. If the counter does not exist, will error. You
   * can choose to handle this and create the counter on failure if desired.
   *
   * If `multiplier` is specified, will behave as if the counter was used that many
   * times.
   */
  counterUse: Counter;
  /** Generate documents using a `DocumentTemplate` and data. */
  documentGenerate: DocumentGeneratePayload;
  /** Print documents to a printer using a URL. */
  documentPrint: DocumentPrintPayload;
  /** Send email(s) using SendGrid. */
  emailSend: EmailSendPayload;
  /** Adds a pack to an `InventoryTransfer`. */
  inventoryTransferAddPack: InventoryTransferAddPackPayload;
  /** Create a new `InventoryTransfer` of status `CREATING` with no packs. */
  inventoryTransferCreate: InventoryTransferCreatePayload;
  /** Soft deletes an `InventoryTransfer`. Keeping the entity data but marking it as deleted */
  inventoryTransferDelete: InventoryTransferDeletePayload;
  /** Set the `InventoryTransfer`s status to `ERROR` if a transfer is created with stock items that are not available at the origin or destination location */
  inventoryTransferGiveUp: InventoryTransferGiveUpPayload;
  /** The `InventoryTransfer` that has just been received at the destination `StockLocation`. Status will change accordingly to `COMPLETE`. */
  inventoryTransferReceive: InventoryTransferReceivePayload;
  /**
   * Change the origin and destination locations for an `InventoryTransfer`. This
   * can be done for both in transit and completed transfers.
   */
  inventoryTransferRedirect: InventoryTransferRedirectPayload;
  /** Remove a pack from an `InventoryTransfer`. */
  inventoryTransferRemovePack: InventoryTransferRemovePackPayload;
  /** The `InventoryTransfer` that has just left the origin `StockLocation`. Status will change accordingly to `IN_TRANSIT`. */
  inventoryTransferStartTransit: InventoryTransferStartTransitPayload;
  /**
   * Moves a `InventoryTransfer` from `COMPLETE` back to `IN_TRANSIT`, and creates
   * stock movements which reverse those that came from receiving. Would be used if
   * someone messed up.
   */
  inventoryTransferUndo: InventoryTransferUndoPayload;
  /** Print a label using a `LabelTemplate` and data. */
  labelPrint: LabelPrintPayload;
  /** Upload a set of presets to a `LabelTemplate`. */
  labelTemplateUploadPresets: LabelTemplateUploadPresetsPayload;
  /** Add packs which are consumed in a `Run`. */
  runAddInfeedPacks: RunAddInfeedPacksPayload;
  runAddOutfeedPackUsingPlan: RunAddOutfeedPackUsingPlanPayload;
  /** Generate a pack representing resultant product from a `Run` */
  runAddOutfeedPacks: Scalars['Boolean'];
  /** Completes a `Run`. */
  runComplete: Scalars['Boolean'];
  /** Create/schedule a `Run`. */
  runCreate: Scalars['ID'];
  /** Reprints the label for a given outfeed pack. */
  runPrintOutfeedPack: Scalars['Boolean'];
  /**
   * Removes an item from a `Run`, when user may have made a mistake
   * scanning.
   */
  runRemovePack: Scalars['Boolean'];
  /** Cancel one or more `Run`s. */
  runsCancel: RunsCancelPayload;
  /** Create/schedule `Run`s. */
  runsCreate: Array<Scalars['ID']>;
  /** Restore one or more `Run`s. */
  runsRestore: Scalars['Boolean'];
  /** Change the planned start date of one or more `Run`s. */
  runsUpdateDate: Scalars['Boolean'];
  /** Change the description/title of one or more `Run`s. */
  runsUpdateDescription: Scalars['Boolean'];
  /** Adjust out packs at a location, 'consuming' the packs. */
  stockConsume: StockConsumePayload;
  /** Create a (Universal Print) pack label and perform related operations. */
  stockCreate: StockCreatePayload;
  /** Create a product pack label for a single product, and adjust the product in. */
  stockCreateSingle: StockCreateSinglePayload;
  /** Attempt to undo the `stockCreateSingle` operation. */
  stockCreateSingleUndo: StockCreateSingleUndoPayload;
  /** Attempt to undo the `stockCreate` operation, or redo after an undo. */
  stockCreateUndo: StockCreateUndoPayload;
  /**
   * Get one or more handles, which can be used to update existing files for approximately
   * 60 minutes. For each element in the input array, one `UploadHandle` is
   * returned. Requires the `Documents.Admin` scope, and the files must be
   * configured with update permissions.
   */
  updateHandlesGet: UpdateHandlesGetPayload;
  /**
   * Get one or more handles, which can be used to upload files for approximately
   * 60 minutes. For each element in the input array, one `UploadHandle` is
   * returned.
   */
  uploadHandlesGet: UploadHandlesGetPayload;
};


export type MutationCounterCreateArgs = {
  input: CounterCreateInput;
};


export type MutationCounterUseArgs = {
  id: Scalars['ID'];
  multiplier?: Maybe<Scalars['Int']>;
};


export type MutationDocumentGenerateArgs = {
  input: DocumentGenerateInput;
};


export type MutationDocumentPrintArgs = {
  input: DocumentPrintInput;
};


export type MutationEmailSendArgs = {
  input: EmailSendInput;
};


export type MutationInventoryTransferAddPackArgs = {
  input: InventoryTransferAddPackInput;
};


export type MutationInventoryTransferCreateArgs = {
  input: InventoryTransferCreateInput;
};


export type MutationInventoryTransferDeleteArgs = {
  input: InventoryTransferDeleteInput;
};


export type MutationInventoryTransferGiveUpArgs = {
  input: InventoryTransferGiveUpInput;
};


export type MutationInventoryTransferReceiveArgs = {
  input: InventoryTransferReceiveInput;
};


export type MutationInventoryTransferRedirectArgs = {
  input: InventoryTransferRedirectInput;
};


export type MutationInventoryTransferRemovePackArgs = {
  input: InventoryTransferRemovePackInput;
};


export type MutationInventoryTransferStartTransitArgs = {
  input: InventoryTransferStartTransitInput;
};


export type MutationInventoryTransferUndoArgs = {
  input: InventoryTransferUndoInput;
};


export type MutationLabelPrintArgs = {
  input: LabelPrintInput;
};


export type MutationLabelTemplateUploadPresetsArgs = {
  input: LabelTemplateUploadPresetsInput;
};


export type MutationRunAddInfeedPacksArgs = {
  input: RunAddInfeedPacksInput;
};


export type MutationRunAddOutfeedPackUsingPlanArgs = {
  input: RunAddOutfeedPackUsingPlanInput;
};


export type MutationRunAddOutfeedPacksArgs = {
  input: RunAddOutfeedPacksInput;
};


export type MutationRunCompleteArgs = {
  input: RunCompleteInput;
};


export type MutationRunCreateArgs = {
  input: RunCreateInput;
};


export type MutationRunPrintOutfeedPackArgs = {
  input: RunPrintOutfeedPackInput;
};


export type MutationRunRemovePackArgs = {
  input: RunRemovePackInput;
};


export type MutationRunsCancelArgs = {
  input: RunsCancelInput;
};


export type MutationRunsCreateArgs = {
  input: RunsCreateInput;
};


export type MutationRunsRestoreArgs = {
  input: RunsRestoreInput;
};


export type MutationRunsUpdateDateArgs = {
  input: RunsUpdateDateInput;
};


export type MutationRunsUpdateDescriptionArgs = {
  input: RunsUpdateDescriptionInput;
};


export type MutationStockConsumeArgs = {
  input: StockConsumeInput;
};


export type MutationStockCreateArgs = {
  input: StockCreateInput;
};


export type MutationStockCreateSingleArgs = {
  input: StockCreateSingleInput;
};


export type MutationStockCreateSingleUndoArgs = {
  input: StockCreateSingleUndoInput;
};


export type MutationStockCreateUndoArgs = {
  input: StockCreateUndoInput;
};


export type MutationUpdateHandlesGetArgs = {
  input: UpdateHandlesGetInput;
};


export type MutationUploadHandlesGetArgs = {
  input: UploadHandlesGetInput;
};

export enum OutfeedPackUnitOfMeasure {
  Lm = 'LM',
  Pce = 'PCE'
}

export type PackDoesNotExistError = {
  __typename?: 'PackDoesNotExistError';
  /** Human-readable error message. */
  message: Scalars['String'];
};

export type PackLog = {
  __typename?: 'PackLog';
  consumed: Scalars['Boolean'];
  deleted: Scalars['Boolean'];
  id: Scalars['ID'];
  lastUpdated: Scalars['DateTime'];
  packNumber: Scalars['String'];
  printer: Scalars['String'];
  /** Quantity of the pack in the stock item's default unit. */
  quantity: Scalars['Float'];
  stockItem: StockItem;
  /**
   * For variable length packs, describes the composition of the pack.
   * If the pack is not of variable length products (or those configured as such),
   * or the pack was created prior to this feature being added, then this will be
   * an empty array.
   */
  tally: Array<Tally>;
  unitNumber?: Maybe<Scalars['String']>;
  unitNumberCategory?: Maybe<Scalars['String']>;
  workstation: PackWorkstation;
};

/** A machine/location at which product can be produced and entered into EXO. */
export type PackWorkstation = {
  __typename?: 'PackWorkstation';
  batchPrintingEnabled: Scalars['Boolean'];
  defaultStockLocation: StockLocation;
  id: Scalars['ID'];
  name: Scalars['String'];
  printer: Scalars['String'];
  stickerPrinter: Scalars['String'];
  stockAdjustmentsDisabled: Scalars['Boolean'];
  /** Link to the corresponding RunType in TimberSmart */
  timberSmartRunTypeId?: Maybe<Scalars['String']>;
  /** Categorizes the `PackWorkstation`. */
  type: PackWorkstationType;
};

/** The kind of pack workstation. */
export enum PackWorkstationType {
  /** Added value - i.e. prebuilt/crafted goods. */
  AddedValue = 'ADDED_VALUE',
  /** Optimizer machine. */
  Opti = 'OPTI',
  /** Producing product using a plan. */
  Planned = 'PLANNED',
  /** Other machine. */
  Standard = 'STANDARD'
}

export type PickGroup = {
  __typename?: 'PickGroup';
  id: Scalars['ID'];
};

/** Information about a file that exists (though might not yet be uploaded). */
export type PresentFile = {
  __typename?: 'PresentFile';
  /**
   * The base URL, but with SAS query parameters providing read access to the
   * file for approximately 60 minutes.
   */
  accessUrl: Scalars['String'];
  /** URI for the location of the blob. */
  baseUrl: Scalars['String'];
  /** Permissions configured for the file. */
  permissions: FilePermissions;
};

/** A device (physical or not) that documents can be printed to. */
export type Printer = {
  __typename?: 'Printer';
  /** Unique ID for the printer */
  id: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  _null?: Maybe<Scalars['String']>;
  /** Fetch a counter without modifying. */
  counter?: Maybe<Counter>;
  /** Fetch all counters without modifying. */
  counters: Array<Counter>;
  /** List of email templates available for printing. */
  emailTemplates: Array<EmailTemplate>;
  /** Get a `File` by id */
  file?: Maybe<File>;
  /** Get `File`s by id */
  files: Array<File>;
  /** Get the `InventoryTransfer` with the given ID, or null if does not exist. */
  inventoryTransfer?: Maybe<InventoryTransfer>;
  /** List `InventoryTransfer`s with filtering and other options. */
  inventoryTransfers: Array<InventoryTransfer>;
  /** Get a `LabelTemplate` by ID if it exists, or null if none found. */
  labelTemplate?: Maybe<LabelTemplate>;
  /** Returns a list of all/many `LabelTemplate`s. */
  labelTemplates: Array<LabelTemplate>;
  /** Get a pack log by it's pack number, returning null if not present. */
  packLogByPackNumber?: Maybe<PackLog>;
  /** Fetch pack logs, in order of most recent to least. */
  packLogs: Array<PackLog>;
  /**
   * Returns the `PackWorkstation` corresponding to the given ID, or null if not
   * found.
   */
  packWorkstation?: Maybe<PackWorkstation>;
  /**
   * Returns the `PackWorkstation` corresponding to the given ID, or null if not
   * found.
   */
  packWorkstationByName?: Maybe<PackWorkstation>;
  /** Returns a list of all/many `PackWorkstation`s. */
  packWorkstations: Array<PackWorkstation>;
  /** Get a printer by ID if it exists. If a printer is returned, it may be printed to. */
  printer?: Maybe<Printer>;
  /** The list of printers that can be printed to */
  printers: Array<Printer>;
  /**
   * Fetch a particular `Run` by a textual unique ID. Returns null if it doesn't
   * exist.
   */
  run?: Maybe<Run>;
  /** Get a `RunOutfeedPlanLine` by ID, or null if it doesn't exist. */
  runOutfeedPlanLine?: Maybe<RunOutfeedPlanLine>;
  /** Fetch a set of `Run`s */
  runs: Array<Run>;
  /**
   * Returns the `StockItem` corresponding to the given stock code, or null if not
   * found.
   */
  stockItem?: Maybe<StockItem>;
  /**
   * Determines the best price possible (price that should be quoted) for a
   * `StockItem` given other information. Takes a list of inputs and returns
   * outputs in the same order.
   */
  stockItemCurrentBestPrices: Array<StockItemBestPriceResult>;
  /** Returns a set of `StockItem`s. */
  stockItems: Array<StockItem>;
  /** Returns a set of `StockItem`s, 'fuzzy searched' on the description field. */
  stockItemsFuzzySearch: Array<StockItem>;
  /** Get a list of all `StockLocation`s. */
  stockLocations: Array<StockLocation>;
  /** Get a list of all `UnitsOfMeasure */
  unitsOfMeasure: Array<UnitOfMeasure>;
  /** Returns the `Workstation` corresponding to the given ID, or null if not found. */
  workstation?: Maybe<Workstation>;
  /** Returns a list of all `Workstations`. */
  workstations: Array<Workstation>;
};


export type QueryCounterArgs = {
  id: Scalars['ID'];
};


export type QueryFileArgs = {
  id: Scalars['ID'];
};


export type QueryFilesArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryInventoryTransferArgs = {
  id: Scalars['ID'];
};


export type QueryInventoryTransfersArgs = {
  count?: Maybe<Scalars['Int']>;
  destinationLocationId?: Maybe<Scalars['ID']>;
  fromId?: Maybe<Scalars['ID']>;
  status?: Maybe<InventoryTransferStatus>;
  userId?: Maybe<Scalars['ID']>;
};


export type QueryLabelTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryLabelTemplatesArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type QueryPackLogByPackNumberArgs = {
  packNumber: Scalars['String'];
};


export type QueryPackLogsArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  packWorkstationId?: Maybe<Scalars['ID']>;
  searchQuery?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  stockCode?: Maybe<Scalars['ID']>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryPackWorkstationArgs = {
  id: Scalars['ID'];
};


export type QueryPackWorkstationByNameArgs = {
  name: Scalars['String'];
};


export type QueryPackWorkstationsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type QueryPrinterArgs = {
  id: Scalars['ID'];
};


export type QueryRunArgs = {
  id: Scalars['ID'];
};


export type QueryRunOutfeedPlanLineArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryRunsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
  includeCanceled?: Maybe<Scalars['Boolean']>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  untilDate?: Maybe<Scalars['Date']>;
  workstationId?: Maybe<Scalars['ID']>;
  workstationType?: Maybe<Scalars['String']>;
};


export type QueryStockItemArgs = {
  stockCode: Scalars['ID'];
};


export type QueryStockItemCurrentBestPricesArgs = {
  input: Array<StockItemBestPriceInput>;
};


export type QueryStockItemsArgs = {
  barcodes?: Maybe<Array<Scalars['String']>>;
  skip?: Maybe<Scalars['Int']>;
  stockCodes?: Maybe<Array<Scalars['ID']>>;
  take?: Maybe<Scalars['Int']>;
};


export type QueryStockItemsFuzzySearchArgs = {
  input: StockItemsFuzzySearchInput;
};


export type QueryWorkstationArgs = {
  id: Scalars['ID'];
};

/** Describes a use of a `Workstation` to consume `Packs` and produce new `Packs`. */
export type Run = {
  __typename?: 'Run';
  /**
   * Whether the run has been canceled. Canceled runs cannot have packs added to
   * them, and tend to be hidden by default.
   */
  canceled: Scalars['Boolean'];
  /** Certification Code the `Run` is set at. */
  certificationCode?: Maybe<Scalars['String']>;
  /** Whether the run already been completed - not scheduled and not in progress. */
  complete: Scalars['Boolean'];
  /** When the run is planned to/did take place. */
  date: Scalars['String'];
  /** Additional information about the `Run`. */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Describes the input of the `Run`. */
  infeed: RunInfeed;
  location: Location;
  /** Describes the output of the `Run`. */
  outfeed: RunOutfeed;
  /** The expected output of the `Run`. */
  outfeedPlan: RunOutfeedPlan;
  /** Supplier name the `Run` is set at. */
  supplierName?: Maybe<Scalars['String']>;
  /** Workstation the `Run` operates on. */
  workstation: Workstation;
};

export type RunAddInfeedPacksError = DuplicatePackError | IncompatiblePackError | PackDoesNotExistError | RunDoesNotExistError;

export type RunAddInfeedPacksInput = {
  packs: Array<RunAddInfeedPacksPackInput>;
  runId: Scalars['ID'];
};

export type RunAddInfeedPacksPackInput = {
  packId: Scalars['ID'];
  processDate: Scalars['DateTime'];
};

export type RunAddInfeedPacksPayload = {
  __typename?: 'RunAddInfeedPacksPayload';
  error?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['String']>;
  /** Human-readable completion message. */
  success: Scalars['Boolean'];
};

export type RunAddOutfeedPackUsingPlanInput = {
  printer: Scalars['String'];
  quantity: Scalars['Float'];
  runOutfeedPlanLineId: Scalars['ID'];
  unitOfMeasure: OutfeedPackUnitOfMeasure;
};

export type RunAddOutfeedPackUsingPlanPayload = {
  __typename?: 'RunAddOutfeedPackUsingPlanPayload';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type RunAddOutfeedPacksInput = {
  packs: Array<RunAddOutfeedPacksPackInput>;
  runId: Scalars['ID'];
};

export type RunAddOutfeedPacksPackInput = {
  gcsId: Scalars['ID'];
  processDate: Scalars['DateTime'];
  setLengthPieces?: Maybe<Scalars['Int']>;
  tally: Array<TallyInput>;
};

export type RunCompleteInput = {
  /**
   * If set to do, will perform cost-spreading when completing.
   *
   * Cost spreading does the following:
   * -	Auto calculates the run input costs (including machining costs)
   * -	Calculates the difference in volume between outfeed and infeed.
   * -	Spreads the weighted difference across all outfeed packs (i.e. smaller packs
   *   will be changed less than larger ones)
   * -	Spreads the costs across the adjusted packets.
   */
  costSpread: Scalars['Boolean'];
  /** ID of the `Run` that should be completed. */
  runId: Scalars['ID'];
};

export type RunCreateInput = {
  /**
   * When the run is planned to take place.
   * Note: Only accepts the date part, time is truncated..
   */
  date: Scalars['Date'];
  /** Optional additional information about the `Run`. */
  description?: Maybe<Scalars['String']>;
  /** ID representing the workstation ID. */
  workstationId: Scalars['ID'];
};

export type RunDoesNotExistError = {
  __typename?: 'RunDoesNotExistError';
  /** Human-readable error message. */
  message: Scalars['String'];
};

/** Describes what enters a `Workstation` during a `Run`. */
export type RunInfeed = {
  __typename?: 'RunInfeed';
  packs: Array<RunInfeedPack>;
};


/** Describes what enters a `Workstation` during a `Run`. */
export type RunInfeedPacksArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

/**
 * Packs consumed during a `Run`. Can be thought of as product entering a
 * `Workstation`.
 */
export type RunInfeedPack = {
  __typename?: 'RunInfeedPack';
  gcsId: Scalars['ID'];
  lastUpdate: Scalars['DateTime'];
  packId: Scalars['ID'];
  processDate: Scalars['Date'];
  processDescription: Scalars['String'];
  processId: Scalars['ID'];
  productCode: Scalars['String'];
  shortCode?: Maybe<Scalars['String']>;
  tally?: Maybe<Array<Maybe<Tally>>>;
  thickness: Scalars['Float'];
  width: Scalars['Float'];
};

/** Describes what leaves a `Workstation` during a `Run`. */
export type RunOutfeed = {
  __typename?: 'RunOutfeed';
  packs: Array<RunOutfeedPack>;
};


/** Describes what leaves a `Workstation` during a `Run`. */
export type RunOutfeedPacksArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

/**
 * Packs created due to a `Run`. Can be thought of as product leaving a
 * `Workstation`.
 */
export type RunOutfeedPack = {
  __typename?: 'RunOutfeedPack';
  gcsId: Scalars['ID'];
  lastUpdate: Scalars['DateTime'];
  packId: Scalars['ID'];
  processDate: Scalars['Date'];
  processDescription: Scalars['String'];
  processId: Scalars['ID'];
  productCode: Scalars['String'];
  tally?: Maybe<Array<Maybe<Tally>>>;
};

/** Describes the expected output of a run. */
export type RunOutfeedPlan = {
  __typename?: 'RunOutfeedPlan';
  /** Each line describes an amount of product expected to be produced by the run. */
  lines: Array<RunOutfeedPlanLine>;
};

/** Describes an amount of product expected to be produced by the run. */
export type RunOutfeedPlanLine = {
  __typename?: 'RunOutfeedPlanLine';
  /**
   * If the packs created should be created as finished packs, this will be
   * non-null and describes the finished product that would be produced by the run.
   * If this is null, the packs produced corresponding to this plan line will be
   * considered work-in-progress goods, and will be tracked in the inventory system
   * rather than the sales system.
   */
  finishedProduct?: Maybe<StockItem>;
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Total LM that has been produced so far for that plan line. */
  linealCompleted?: Maybe<Scalars['Float']>;
  /** Total LM expected to be produced by the run. */
  linealPlanned?: Maybe<Scalars['Float']>;
  /**
   * Describes the next operation expected to be performed on the packs produced
   * according to the plan.
   */
  nextAction?: Maybe<Scalars['String']>;
  /** Describes the composition/type of pack as a raw string. */
  packBuild?: Maybe<Scalars['String']>;
  /** Total piece count expected to be produced by the run. */
  piecesPlanned?: Maybe<Scalars['Float']>;
  /** The run the line belongs to. */
  run: Run;
  /** The unit number the packs are being created to fulfil. */
  unitNumber?: Maybe<Scalars['String']>;
  /** The product to produce. */
  wipProduct: WipProduct;
};

export type RunPrintOutfeedPackInput = {
  packId: Scalars['ID'];
  runId: Scalars['ID'];
};

export type RunRemovePackInput = {
  outfeed?: Maybe<Scalars['Boolean']>;
  packId: Scalars['ID'];
  runId: Scalars['ID'];
};

export type RunUpdateDateInput = {
  /** The new date (UTC). */
  date: Scalars['DateTime'];
  /** The `Run` to update. */
  runId: Scalars['ID'];
};

export type RunsCancelInput = {
  /** The `Run`s to cancel. */
  runIds: Array<Scalars['ID']>;
};

export type RunsCancelPayload = {
  __typename?: 'RunsCancelPayload';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type RunsCreateInput = {
  runs: Array<RunsCreateRunInput>;
};

export type RunsCreateRunInput = {
  /** When the run is planned to take place. UTC timestamp. */
  date: Scalars['DateTime'];
  /** Optional additional information about the `Run`. */
  description?: Maybe<Scalars['String']>;
  /** ID representing the workstation ID. */
  workstationId: Scalars['ID'];
};

export type RunsRestoreInput = {
  /** The `Run`s to restore. */
  runIds: Array<Scalars['ID']>;
};

export type RunsUpdateDateInput = {
  updates: Array<RunUpdateDateInput>;
};

export type RunsUpdateDescriptionInput = {
  updates: Array<RunsUpdateDescriptionUpdateInput>;
};

export type RunsUpdateDescriptionUpdateInput = {
  /** The new description. */
  description: Scalars['String'];
  /** The `Run` to update. */
  runId: Scalars['ID'];
};

export type SalesBranch = {
  __typename?: 'SalesBranch';
  id: Scalars['ID'];
};

export type SalesOrder = {
  __typename?: 'SalesOrder';
  id: Scalars['ID'];
};

export type SalesOrderLine = {
  __typename?: 'SalesOrderLine';
  id: Scalars['ID'];
};

export type ShippingProvider = {
  __typename?: 'ShippingProvider';
  id: Scalars['ID'];
};

export type StockConsumeError = StockConsumeNotFoundError;

export type StockConsumeInput = {
  /** The pack numbers of the packs to consume. */
  packNumbers: Array<Scalars['String']>;
  /** Where the packs are located. */
  stockLocationId: Scalars['ID'];
};

export type StockConsumeNotFoundError = UserError & {
  __typename?: 'StockConsumeNotFoundError';
  message: Scalars['String'];
};

export type StockConsumePayload = {
  __typename?: 'StockConsumePayload';
  userErrors: Array<StockConsumeError>;
};

export type StockCreateError = StockCreateInvalidInputError | StockCreateNotFoundError;

export type StockCreateInput = {
  displayDimensions?: Maybe<Scalars['String']>;
  lengthSummary?: Maybe<Scalars['String']>;
  packWorkstationId: Scalars['ID'];
  printQuantity: Scalars['Int'];
  printer: Scalars['String'];
  quantity: Scalars['Float'];
  selectedUnitOfMeasure: StockCreateUnitOfMeasure;
  stockCode: Scalars['String'];
  tally?: Maybe<Array<TallyInput>>;
  unitNumber?: Maybe<Scalars['String']>;
  variableLengthPieces?: Maybe<Scalars['Float']>;
};

export type StockCreateInvalidInputError = UserError & {
  __typename?: 'StockCreateInvalidInputError';
  message: Scalars['String'];
};

export type StockCreateNotFoundError = UserError & {
  __typename?: 'StockCreateNotFoundError';
  message: Scalars['String'];
};

export type StockCreatePayload = {
  __typename?: 'StockCreatePayload';
  /** The resulting `PackLog`s, null on failure. */
  packLogs?: Maybe<Array<PackLog>>;
  userErrors: Array<StockCreateError>;
};

export type StockCreateSingleError = StockCreateSingleNoBarcodeError | StockCreateSingleNotFoundError;

export type StockCreateSingleInput = {
  packWorkstationId: Scalars['ID'];
  printer: Scalars['String'];
  stockCode: Scalars['String'];
};

export type StockCreateSingleNoBarcodeError = UserError & {
  __typename?: 'StockCreateSingleNoBarcodeError';
  message: Scalars['String'];
};

export type StockCreateSingleNotFoundError = UserError & {
  __typename?: 'StockCreateSingleNotFoundError';
  message: Scalars['String'];
};

export type StockCreateSinglePayload = {
  __typename?: 'StockCreateSinglePayload';
  userErrors: Array<StockCreateSingleError>;
};

export type StockCreateSingleUndoError = StockCreateSingleUndoNotFoundError;

export type StockCreateSingleUndoInput = {
  packWorkstationId: Scalars['ID'];
  stockCode: Scalars['String'];
};

export type StockCreateSingleUndoNotFoundError = UserError & {
  __typename?: 'StockCreateSingleUndoNotFoundError';
  message: Scalars['String'];
};

export type StockCreateSingleUndoPayload = {
  __typename?: 'StockCreateSingleUndoPayload';
  userErrors: Array<StockCreateSingleUndoError>;
};

export type StockCreateUndoError = StockCreateUndoNotFoundError;

export type StockCreateUndoInput = {
  /** The pack log to delete or restore. */
  packLogId: Scalars['ID'];
  /** Whether to soft-delete the pack log or restore it. */
  restore?: Maybe<Scalars['Boolean']>;
};

export type StockCreateUndoNotFoundError = UserError & {
  __typename?: 'StockCreateUndoNotFoundError';
  message: Scalars['String'];
};

export type StockCreateUndoPayload = {
  __typename?: 'StockCreateUndoPayload';
  /** The resulting `PackLog`, null on failure. */
  packLog?: Maybe<PackLog>;
  userErrors: Array<StockCreateUndoError>;
};

export enum StockCreateUnitOfMeasure {
  Lm = 'LM',
  Pce = 'PCE'
}

/** A category for a `StockItem`. */
export type StockGroup = {
  __typename?: 'StockGroup';
  id: Scalars['ID'];
  /** Name of the group. */
  name: Scalars['String'];
};

/** An item, typically physical, that is sold for a given price to `Debtor`s. */
export type StockItem = {
  __typename?: 'StockItem';
  /** Important information to consider when using the `StockItem`, if any. */
  alert?: Maybe<Scalars['String']>;
  /** Barcode/GTIN, or null if not available. */
  barcode?: Maybe<Scalars['String']>;
  /**
   * The value of the product's customer name field in the sales system. Indicates
   * the customer we would typically build this product up in stock for.
   */
  customerName: Scalars['String'];
  /** The last time the `StockItem` record was modified. May return null. */
  dateModified?: Maybe<Scalars['Date']>;
  /** Default location for dispatching the product, if any. */
  defaultStockLocation?: Maybe<StockLocation>;
  /**
   * Any other barcodes that should be recognized as this product, but should not
   * be used by us for the barcode was changed.
   *
   * Ideally we should never change barcodes and this should never be needed, but
   * unfortunately some customers order from us with these barcodes and we need to
   * understand that.
   */
  deprecatedBarcodes: Array<Scalars['String']>;
  /** Sales description. */
  description?: Maybe<Scalars['String']>;
  /**
   * Scaling factor when converting from the order quantity in Get Growing to order
   * quantity in the sales system. For example, if we are selling the product as a
   * 10 pack in Get Growing, this field should be set to 10, so ordering 3 in Get
   * Growing would become 30 in our system.
   */
  getGrowingQuantityMultiplier: Scalars['Float'];
  /** Whether the `StockItem` is actively in use/not retired. */
  isActive: Scalars['Boolean'];
  /** Whether or not the product is considered a 'Variable Length' product. */
  isVariableLengthProduct: Scalars['Boolean'];
  /** Whether or not the product is considered a 'WIP' product. */
  isWipProduct: Scalars['Boolean'];
  /**
   * Weight of the product in kilograms, or null if not available.
   *
   * A value of zero in EXO is assumed to be not specified and thus is null here.
   */
  kilograms?: Maybe<Scalars['Float']>;
  /** Length of the product in millimeters, or null if not available. */
  lengthMillimeters?: Maybe<Scalars['Float']>;
  /** Information about a `StockItem` at the given `StockLocation`. */
  locationInfo: StockItemLocationInfo;
  /** Information about a `StockItem` at each `StockLocation`. */
  locationInfos: Array<StockItemLocationInfo>;
  /**
   * Volume of the product in meters cubed, or null if not available.
   *
   * A value of zero in EXO is assumed to be not specified and thus is null here.
   */
  metresCubed?: Maybe<Scalars['Float']>;
  /**
   * The minimum quantity we are willing to sell of the product in an individual
   * order.
   */
  minimumOrderQuantity?: Maybe<Scalars['Float']>;
  /**
   * Name for the `StockItem`, typically shorter and less detailed than
   * description. If no name is available, will default back to `description`
   */
  name: Scalars['String'];
  /** Extra notes on/about the product, if there are any. */
  notes?: Maybe<Scalars['String']>;
  /**
   * The number/quantity of items in a typical pack/bundle. Could be floating-point
   * e.g. units of linear meters.
   */
  quantityMultiplier: Scalars['Float'];
  /**
   * The price at which the product is typically sold at for the given `Debtor`,
   * or null if not available.
   */
  sellPrice?: Maybe<Scalars['Float']>;
  /**
   * The price at which the product is typically sold at for debtors corresponding
   * to the given index (price group).
   */
  sellPriceAtIndex?: Maybe<Scalars['Float']>;
  /**
   * The pack size of a typical pack of this product, if it is a product that
   * can be made into packs.
   */
  standardPackSize?: Maybe<Scalars['Int']>;
  /** ID of the stock item, often referred to as the 'stock code'. */
  stockCode: Scalars['ID'];
  /** Group the `StockItem` belongs to. */
  stockGroup: StockGroup;
  /** Thickness/height of the product in millimeters, or null if not available. */
  thicknessMillimeters?: Maybe<Scalars['Float']>;
  /** Unit usually used to measure the quantity of this product. */
  unitOfMeasure: UnitOfMeasure;
  /** Width of the product in millimeters, or null if not available. */
  widthMillimeters?: Maybe<Scalars['Float']>;
  /** Whether or not the product is wrapped. */
  wrapped: Scalars['Boolean'];
};


/** An item, typically physical, that is sold for a given price to `Debtor`s. */
export type StockItemLocationInfoArgs = {
  stockLocationId: Scalars['ID'];
};


/** An item, typically physical, that is sold for a given price to `Debtor`s. */
export type StockItemSellPriceArgs = {
  debtorId: Scalars['ID'];
};


/** An item, typically physical, that is sold for a given price to `Debtor`s. */
export type StockItemSellPriceAtIndexArgs = {
  index: Scalars['Int'];
};

export type StockItemBestPriceInput = {
  /** The `Debtor` that would be purchasing. */
  debtorId: Scalars['ID'];
  /** The amount of the `StockItem` that would be ordered. */
  orderQuantity: Scalars['Float'];
  /** The `StockItem` that would be purchased. */
  stockCode: Scalars['ID'];
  /** Applies tax if required to the returned price. Defaults to GST exclusive. */
  taxType?: Maybe<TaxType>;
};

export type StockItemBestPriceResult = {
  __typename?: 'StockItemBestPriceResult';
  /** The determined price, if it could. */
  price?: Maybe<Scalars['Float']>;
};

/** Information about a `StockItem` at a particular `StockLocation`. */
export type StockItemLocationInfo = {
  __typename?: 'StockItemLocationInfo';
  /**
   * The bin code for the `StockItem` at the `StockLocation`.
   *
   * A bin code is a short string code used to help determine the physical location
   * (e.g. which shelf) of a `StockItem` within a larger location.
   *
   * Note that if the `StockItem` does not have any special bin code set for the
   * location, it will use the default, which is set on the `StockItem` itself.
   *
   * If no bin code is found whatsoever, null will be returned.
   */
  binCode?: Maybe<Scalars['String']>;
  /**
   * The current quantity of the `StockItem` available at the `StockLocation` in
   * the appropriate unit, at least as far as the financial system knows.
   */
  freeQuantity: Scalars['Float'];
  /**
   * The current quantity of the `StockItem` present at present in the
   * `StockLocation` in the appropriate unit, at least as far as the financial
   * system knows.
   */
  presentQuantity: Scalars['Float'];
  /** The `StockLocation` the information pertains to. */
  stockLocation: StockLocation;
};

export type StockItemsFuzzySearchInput = {
  description?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
};

/** A place from which goods can be stored/taken. */
export type StockLocation = {
  __typename?: 'StockLocation';
  /** Short code for the location. */
  code: Scalars['String'];
  id: Scalars['ID'];
  /** Full name of the location. */
  name: Scalars['String'];
};

/**
 * A measurement of the amount of product.
 * It includes the number of pieces, and the length of these pieces.
 */
export type Tally = {
  __typename?: 'Tally';
  length: Scalars['Float'];
  pieces: Scalars['Int'];
};

/** Corresponding input for `Tally` objects. */
export type TallyInput = {
  length: Scalars['Float'];
  pieces: Scalars['Int'];
};

export enum TaxType {
  GstExclusive = 'GST_EXCLUSIVE',
  GstInclusive = 'GST_INCLUSIVE'
}

/** Data for units for measuring physical objects/goods. */
export type UnitOfMeasure = {
  __typename?: 'UnitOfMeasure';
  /** Name enum for different units. */
  name: UnitOfMeasureName;
  /** Lowercase symbol. */
  symbol: Scalars['String'];
  /** Uppercase symbol. */
  symbolCapitalized: Scalars['String'];
  /** English name. */
  word: Scalars['String'];
  /** English name, but pluralized. */
  wordPlural: Scalars['String'];
};

/** Units for measuring physical objects/goods. */
export enum UnitOfMeasureName {
  /** Board Foot (volume of a 1 by 1 foot board of 1 inch thickness. */
  Bdft = 'BDFT',
  /** Boxes/cartons. */
  Box = 'BOX',
  /** Linear Metres. */
  Lm = 'LM',
  /** Metres Cubed. */
  M3 = 'M3',
  /** Thousand Board Feet. (= 1000 x BDFT) */
  Mbf = 'MBF',
  /** Pieces. */
  Pce = 'PCE',
  /** Unit does not match one of those predefined by the benevolent developers. */
  Unknown = 'UNKNOWN'
}

export type UpdateHandlesGetError = UpdateHandlesGetNotFoundError | UpdateHandlesGetNotModifiableError;

export type UpdateHandlesGetHandleInput = {
  /** Content type for the blob, e.g. "application/pdf" */
  contentType: Scalars['String'];
  /** ID of the file to update */
  fileId: Scalars['ID'];
  /**
   * The new name for the file. Even when null, this will rename the old file. This
   * does not affect the name of the blob, just the name of the file according to
   * the API.
   */
  filename?: Maybe<Scalars['String']>;
};

export type UpdateHandlesGetInput = {
  handles: Array<UpdateHandlesGetHandleInput>;
};

export type UpdateHandlesGetNotFoundError = UserError & {
  __typename?: 'UpdateHandlesGetNotFoundError';
  message: Scalars['String'];
};

export type UpdateHandlesGetNotModifiableError = UserError & {
  __typename?: 'UpdateHandlesGetNotModifiableError';
  message: Scalars['String'];
};

export type UpdateHandlesGetPayload = {
  __typename?: 'UpdateHandlesGetPayload';
  /** The handles to use for uploading files. */
  handles?: Maybe<Array<UploadHandle>>;
  userErrors: Array<UpdateHandlesGetError>;
};


/** A handle to a file in Azure Blob Storage. */
export type UploadHandle = {
  __typename?: 'UploadHandle';
  /** The file record created. */
  file: File;
  /** URL which can be used to upload a file */
  uploadUrl: Scalars['String'];
};

export type UploadHandlesGetError = UploadHandlesGetInvalidInputError;

export type UploadHandlesGetHandleInput = {
  /** The file extension for the blob name, if any. */
  blobExtension?: Maybe<Scalars['String']>;
  /**
   * If not set, the blob will be stored internally as
   * "<some-uuid>.<blobExtension>", or if no blob file extension is present, just
   * "<some-uuid>".
   *
   * If set, the blob will be stored as "<prefix> <some-uuid>.<blobExtension>",
   * or if no blob file extension is present, just "<prefix> <some-uuid>"".
   */
  blobNamePrefix?: Maybe<Scalars['String']>;
  /**
   * The name of the container to place the blob in. This container does not
   * need to exist to be used - it will be created as required.
   */
  containerName: Scalars['String'];
  /** Content type for the blob, e.g. "application/pdf" */
  contentType: Scalars['String'];
  /**
   * If null, won't ever expire. If set, must be positive. Files will not be
   * deleted until after `expireDays` is met, although the deletion is not
   * immediate.
   */
  expireDays?: Maybe<Scalars['Int']>;
  /** Filename for the file according to the API, if any. */
  filename?: Maybe<Scalars['String']>;
  /**
   * Permissions that apply to the file. Only enforceable if file operations
   * happen through this api
   */
  permissions?: Maybe<FilePermissions>;
};

export type UploadHandlesGetInput = {
  handles: Array<UploadHandlesGetHandleInput>;
};

export type UploadHandlesGetInvalidInputError = UserError & {
  __typename?: 'UploadHandlesGetInvalidInputError';
  message: Scalars['String'];
};

export type UploadHandlesGetPayload = {
  __typename?: 'UploadHandlesGetPayload';
  /** The handles to use for uploading files. */
  handles?: Maybe<Array<UploadHandle>>;
  userErrors: Array<UploadHandlesGetError>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
};

export type UserError = {
  message: Scalars['String'];
};

export type WipProduct = {
  __typename?: 'WipProduct';
  /** Description of the product. */
  description: Scalars['String'];
  /** Grade of the wood the product is composed of. */
  grade: Scalars['String'];
  /** Unique identifier. Corresponds to the product's GCS ID in TimberSmart. */
  id: Scalars['ID'];
  /** Unique and meaningful identifier for the product. */
  productCode: Scalars['String'];
  /** The fixed/set length of the product configured in the inventory system. */
  setLength?: Maybe<Scalars['Float']>;
};

export type WipSalesOrder = {
  __typename?: 'WipSalesOrder';
  id: Scalars['ID'];
};

/** A machine/location at which product can be produced or manipulated. */
export type Workstation = {
  __typename?: 'Workstation';
  id: Scalars['ID'];
  /** The label printer to use when a `User` at the machine wants to print a label. */
  labelPrinterId?: Maybe<Scalars['ID']>;
  /** Friendly name. */
  name: Scalars['String'];
  /** The corresponding pack workstation, if any. */
  packWorkstation?: Maybe<PackWorkstation>;
  /** Categorizes the `Workstation`. */
  type: WorkstationType;
};

/** Different types of `Workstation`. */
export enum WorkstationType {
  /** Makes packs disappear. */
  FactoryIn = 'FACTORY_IN',
  /** Preparing timber for drying. */
  Filleting = 'FILLETING',
  /** Any other type of `Workstation` that doesn't suit the other types. */
  Other = 'OTHER'
}

export type CreatePackLogMutationVariables = Exact<{
  input: StockCreateInput;
}>;


export type CreatePackLogMutation = (
  { __typename?: 'Mutation' }
  & { stockCreate: (
    { __typename?: 'StockCreatePayload' }
    & { userErrors: Array<(
      { __typename: 'StockCreateInvalidInputError' }
      & Pick<StockCreateInvalidInputError, 'message'>
    ) | (
      { __typename: 'StockCreateNotFoundError' }
      & Pick<StockCreateNotFoundError, 'message'>
    )> }
  ) }
);

export type CreateSingleMutationVariables = Exact<{
  input: StockCreateSingleInput;
}>;


export type CreateSingleMutation = (
  { __typename?: 'Mutation' }
  & { stockCreateSingle: (
    { __typename?: 'StockCreateSinglePayload' }
    & { userErrors: Array<(
      { __typename: 'StockCreateSingleNoBarcodeError' }
      & Pick<StockCreateSingleNoBarcodeError, 'message'>
    ) | (
      { __typename: 'StockCreateSingleNotFoundError' }
      & Pick<StockCreateSingleNotFoundError, 'message'>
    )> }
  ) }
);

export type CreateSingleUndoMutationVariables = Exact<{
  input: StockCreateSingleUndoInput;
}>;


export type CreateSingleUndoMutation = (
  { __typename?: 'Mutation' }
  & { stockCreateSingleUndo: (
    { __typename?: 'StockCreateSingleUndoPayload' }
    & { userErrors: Array<(
      { __typename: 'StockCreateSingleUndoNotFoundError' }
      & Pick<StockCreateSingleUndoNotFoundError, 'message'>
    )> }
  ) }
);

export type DeletePackLogMutationVariables = Exact<{
  input: StockCreateUndoInput;
}>;


export type DeletePackLogMutation = (
  { __typename?: 'Mutation' }
  & { stockCreateUndo: (
    { __typename?: 'StockCreateUndoPayload' }
    & { packLog?: Maybe<(
      { __typename?: 'PackLog' }
      & Pick<PackLog, 'id' | 'deleted'>
    )>, userErrors: Array<(
      { __typename: 'StockCreateUndoNotFoundError' }
      & Pick<StockCreateUndoNotFoundError, 'message'>
    )> }
  ) }
);

export type UploadPresetsMutationVariables = Exact<{
  input: LabelTemplateUploadPresetsInput;
}>;


export type UploadPresetsMutation = (
  { __typename?: 'Mutation' }
  & { labelTemplateUploadPresets: (
    { __typename?: 'LabelTemplateUploadPresetsPayload' }
    & { userErrors: Array<(
      { __typename: 'LabelTemplateUploadPresetsInvalidDataError' }
      & Pick<LabelTemplateUploadPresetsInvalidDataError, 'message'>
    ) | (
      { __typename: 'LabelTemplateUploadPresetsNotFoundError' }
      & Pick<LabelTemplateUploadPresetsNotFoundError, 'message'>
    )> }
  ) }
);

export type PrintLabelsMutationVariables = Exact<{
  input: LabelPrintInput;
}>;


export type PrintLabelsMutation = (
  { __typename?: 'Mutation' }
  & { labelPrint: (
    { __typename?: 'LabelPrintPayload' }
    & Pick<LabelPrintPayload, 'message'>
  ) }
);

export type RunAddOutfeedPackUsingPlanMutationVariables = Exact<{
  input: RunAddOutfeedPackUsingPlanInput;
}>;


export type RunAddOutfeedPackUsingPlanMutation = (
  { __typename?: 'Mutation' }
  & { runAddOutfeedPackUsingPlan: (
    { __typename?: 'RunAddOutfeedPackUsingPlanPayload' }
    & Pick<RunAddOutfeedPackUsingPlanPayload, 'message' | 'success'>
  ) }
);

export type UndoInventoryPackMutationVariables = Exact<{
  input: RunRemovePackInput;
}>;


export type UndoInventoryPackMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'runRemovePack'>
);

export type StockItemFragment = (
  { __typename?: 'StockItem' }
  & Pick<StockItem, 'stockCode' | 'name' | 'isActive' | 'description' | 'widthMillimeters' | 'thicknessMillimeters' | 'lengthMillimeters' | 'kilograms' | 'metresCubed' | 'standardPackSize' | 'wrapped' | 'customerName' | 'isVariableLengthProduct'>
  & { unitOfMeasure: (
    { __typename?: 'UnitOfMeasure' }
    & Pick<UnitOfMeasure, 'name'>
  ) }
);

export type FindStockItemByStockCodeQueryVariables = Exact<{
  stockCode: Scalars['ID'];
}>;


export type FindStockItemByStockCodeQuery = (
  { __typename?: 'Query' }
  & { stockItem?: Maybe<(
    { __typename?: 'StockItem' }
    & StockItemFragment
  )> }
);

export type FindStockItemQueryVariables = Exact<{
  stockCode: Scalars['ID'];
  barcode: Scalars['String'];
}>;


export type FindStockItemQuery = (
  { __typename?: 'Query' }
  & { byCode?: Maybe<(
    { __typename?: 'StockItem' }
    & StockItemFragment
  )>, byBarcode: Array<(
    { __typename?: 'StockItem' }
    & StockItemFragment
  )> }
);

export type FindWorkstationByNameQueryVariables = Exact<{
  workstation: Scalars['String'];
}>;


export type FindWorkstationByNameQuery = (
  { __typename?: 'Query' }
  & { packWorkstationByName?: Maybe<(
    { __typename?: 'PackWorkstation' }
    & Pick<PackWorkstation, 'id' | 'name' | 'type' | 'timberSmartRunTypeId' | 'stockAdjustmentsDisabled' | 'batchPrintingEnabled' | 'printer' | 'stickerPrinter'>
  )> }
);

export type RecentPackLogsQueryVariables = Exact<{
  workstationId?: Maybe<Scalars['ID']>;
  take: Scalars['Int'];
  searchQuery?: Maybe<Scalars['String']>;
}>;


export type RecentPackLogsQuery = (
  { __typename?: 'Query' }
  & { packLogs: Array<(
    { __typename?: 'PackLog' }
    & Pick<PackLog, 'id' | 'packNumber' | 'quantity' | 'lastUpdated' | 'deleted'>
    & { stockItem: (
      { __typename?: 'StockItem' }
      & Pick<StockItem, 'stockCode'>
      & { unitOfMeasure: (
        { __typename?: 'UnitOfMeasure' }
        & Pick<UnitOfMeasure, 'name'>
      ) }
    ) }
  )> }
);

export type PrintersQueryVariables = Exact<{ [key: string]: never; }>;


export type PrintersQuery = (
  { __typename?: 'Query' }
  & { printers: Array<(
    { __typename?: 'Printer' }
    & Pick<Printer, 'id'>
  )> }
);

export type LabelTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type LabelTemplatesQuery = (
  { __typename?: 'Query' }
  & { labelTemplates: Array<(
    { __typename?: 'LabelTemplate' }
    & Pick<LabelTemplate, 'id' | 'name' | 'category' | 'presetKind'>
  )> }
);

export type LabelTemplateQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LabelTemplateQuery = (
  { __typename?: 'Query' }
  & { labelTemplate?: Maybe<(
    { __typename?: 'LabelTemplate' }
    & Pick<LabelTemplate, 'id' | 'name' | 'category' | 'defaultPrinter' | 'presetKind' | 'presets'>
    & { fields: Array<(
      { __typename?: 'LabelTemplateField' }
      & Pick<LabelTemplateField, 'name' | 'internalName' | 'preset' | 'extraInfo' | 'kind' | 'defaultLineCount'>
    )> }
  )> }
);

export type LabelTemplatePresetDownloadQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LabelTemplatePresetDownloadQuery = (
  { __typename?: 'Query' }
  & { labelTemplate?: Maybe<(
    { __typename?: 'LabelTemplate' }
    & Pick<LabelTemplate, 'id' | 'presetDocument'>
  )> }
);

export type RunQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RunQuery = (
  { __typename?: 'Query' }
  & { run?: Maybe<(
    { __typename?: 'Run' }
    & Pick<Run, 'id' | 'complete' | 'date' | 'description'>
    & { outfeedPlan: (
      { __typename?: 'RunOutfeedPlan' }
      & { lines: Array<(
        { __typename?: 'RunOutfeedPlanLine' }
        & Pick<RunOutfeedPlanLine, 'id' | 'linealPlanned' | 'piecesPlanned' | 'linealCompleted' | 'packBuild' | 'nextAction' | 'unitNumber'>
        & { wipProduct: (
          { __typename?: 'WipProduct' }
          & Pick<WipProduct, 'id' | 'description' | 'setLength'>
        ), finishedProduct?: Maybe<(
          { __typename?: 'StockItem' }
          & Pick<StockItem, 'stockCode' | 'description' | 'thicknessMillimeters' | 'widthMillimeters' | 'lengthMillimeters' | 'standardPackSize'>
        )> }
      )> }
    ) }
  )> }
);

export type RunsQueryVariables = Exact<{
  untilDate?: Maybe<Scalars['Date']>;
  workstationId?: Maybe<Scalars['ID']>;
}>;


export type RunsQuery = (
  { __typename?: 'Query' }
  & { runs: Array<(
    { __typename?: 'Run' }
    & Pick<Run, 'id' | 'complete' | 'date' | 'description'>
    & { workstation: (
      { __typename?: 'Workstation' }
      & Pick<Workstation, 'name' | 'type'>
    ), outfeedPlan: (
      { __typename?: 'RunOutfeedPlan' }
      & { lines: Array<(
        { __typename?: 'RunOutfeedPlanLine' }
        & Pick<RunOutfeedPlanLine, 'id'>
      )> }
    ) }
  )> }
);

export type AutofillPackLogsQueryVariables = Exact<{
  workstationId: Scalars['ID'];
}>;


export type AutofillPackLogsQuery = (
  { __typename?: 'Query' }
  & { packLogs: Array<(
    { __typename?: 'PackLog' }
    & Pick<PackLog, 'id' | 'quantity' | 'deleted' | 'unitNumber'>
    & { tally: Array<(
      { __typename?: 'Tally' }
      & Pick<Tally, 'length' | 'pieces'>
    )>, stockItem: (
      { __typename?: 'StockItem' }
      & Pick<StockItem, 'stockCode' | 'customerName'>
      & { unitOfMeasure: (
        { __typename?: 'UnitOfMeasure' }
        & Pick<UnitOfMeasure, 'name'>
      ) }
    ) }
  )> }
);

export type WorkstationRunsQueryVariables = Exact<{
  runTypeId: Scalars['ID'];
  untilDate: Scalars['Date'];
}>;


export type WorkstationRunsQuery = (
  { __typename?: 'Query' }
  & { runs: Array<(
    { __typename?: 'Run' }
    & Pick<Run, 'id' | 'description'>
  )> }
);

export type RunOutfeedQueryVariables = Exact<{
  runId: Scalars['ID'];
}>;


export type RunOutfeedQuery = (
  { __typename?: 'Query' }
  & { run?: Maybe<(
    { __typename?: 'Run' }
    & Pick<Run, 'id'>
    & { outfeed: (
      { __typename?: 'RunOutfeed' }
      & { packs: Array<(
        { __typename?: 'RunOutfeedPack' }
        & Pick<RunOutfeedPack, 'packId' | 'productCode' | 'processDate'>
      )> }
    ) }
  )> }
);

export type PackHistoryQueryVariables = Exact<{
  packWorkstationId?: Maybe<Scalars['ID']>;
  stockCode?: Maybe<Scalars['ID']>;
  take?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  searchQuery?: Maybe<Scalars['String']>;
}>;


export type PackHistoryQuery = (
  { __typename?: 'Query' }
  & { packLogs: Array<(
    { __typename?: 'PackLog' }
    & Pick<PackLog, 'id' | 'packNumber' | 'lastUpdated' | 'quantity' | 'unitNumber' | 'unitNumberCategory' | 'deleted'>
    & { workstation: (
      { __typename?: 'PackWorkstation' }
      & Pick<PackWorkstation, 'id' | 'name'>
    ), stockItem: (
      { __typename?: 'StockItem' }
      & Pick<StockItem, 'stockCode' | 'description'>
      & { unitOfMeasure: (
        { __typename?: 'UnitOfMeasure' }
        & Pick<UnitOfMeasure, 'symbolCapitalized'>
      ) }
    ), tally: Array<(
      { __typename?: 'Tally' }
      & Pick<Tally, 'length' | 'pieces'>
    )> }
  )> }
);

export type WorkstationsQueryVariables = Exact<{ [key: string]: never; }>;


export type WorkstationsQuery = (
  { __typename?: 'Query' }
  & { packWorkstations: Array<(
    { __typename?: 'PackWorkstation' }
    & Pick<PackWorkstation, 'id' | 'name'>
  )> }
);

export const StockItemFragmentDoc = gql`
    fragment StockItem on StockItem {
  stockCode
  name
  isActive
  description
  widthMillimeters
  thicknessMillimeters
  lengthMillimeters
  unitOfMeasure {
    name
  }
  kilograms
  metresCubed
  standardPackSize
  wrapped
  customerName
  isVariableLengthProduct
}
    `;
export const CreatePackLogDocument = gql`
    mutation CreatePackLog($input: StockCreateInput!) {
  stockCreate(input: $input) {
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type CreatePackLogMutationFn = Apollo.MutationFunction<CreatePackLogMutation, CreatePackLogMutationVariables>;

/**
 * __useCreatePackLogMutation__
 *
 * To run a mutation, you first call `useCreatePackLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePackLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPackLogMutation, { data, loading, error }] = useCreatePackLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePackLogMutation(baseOptions?: Apollo.MutationHookOptions<CreatePackLogMutation, CreatePackLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePackLogMutation, CreatePackLogMutationVariables>(CreatePackLogDocument, options);
      }
export type CreatePackLogMutationHookResult = ReturnType<typeof useCreatePackLogMutation>;
export type CreatePackLogMutationResult = Apollo.MutationResult<CreatePackLogMutation>;
export type CreatePackLogMutationOptions = Apollo.BaseMutationOptions<CreatePackLogMutation, CreatePackLogMutationVariables>;
export const CreateSingleDocument = gql`
    mutation CreateSingle($input: StockCreateSingleInput!) {
  stockCreateSingle(input: $input) {
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type CreateSingleMutationFn = Apollo.MutationFunction<CreateSingleMutation, CreateSingleMutationVariables>;

/**
 * __useCreateSingleMutation__
 *
 * To run a mutation, you first call `useCreateSingleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSingleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSingleMutation, { data, loading, error }] = useCreateSingleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSingleMutation(baseOptions?: Apollo.MutationHookOptions<CreateSingleMutation, CreateSingleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSingleMutation, CreateSingleMutationVariables>(CreateSingleDocument, options);
      }
export type CreateSingleMutationHookResult = ReturnType<typeof useCreateSingleMutation>;
export type CreateSingleMutationResult = Apollo.MutationResult<CreateSingleMutation>;
export type CreateSingleMutationOptions = Apollo.BaseMutationOptions<CreateSingleMutation, CreateSingleMutationVariables>;
export const CreateSingleUndoDocument = gql`
    mutation CreateSingleUndo($input: StockCreateSingleUndoInput!) {
  stockCreateSingleUndo(input: $input) {
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type CreateSingleUndoMutationFn = Apollo.MutationFunction<CreateSingleUndoMutation, CreateSingleUndoMutationVariables>;

/**
 * __useCreateSingleUndoMutation__
 *
 * To run a mutation, you first call `useCreateSingleUndoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSingleUndoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSingleUndoMutation, { data, loading, error }] = useCreateSingleUndoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSingleUndoMutation(baseOptions?: Apollo.MutationHookOptions<CreateSingleUndoMutation, CreateSingleUndoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSingleUndoMutation, CreateSingleUndoMutationVariables>(CreateSingleUndoDocument, options);
      }
export type CreateSingleUndoMutationHookResult = ReturnType<typeof useCreateSingleUndoMutation>;
export type CreateSingleUndoMutationResult = Apollo.MutationResult<CreateSingleUndoMutation>;
export type CreateSingleUndoMutationOptions = Apollo.BaseMutationOptions<CreateSingleUndoMutation, CreateSingleUndoMutationVariables>;
export const DeletePackLogDocument = gql`
    mutation DeletePackLog($input: StockCreateUndoInput!) {
  stockCreateUndo(input: $input) {
    packLog {
      id
      deleted
    }
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type DeletePackLogMutationFn = Apollo.MutationFunction<DeletePackLogMutation, DeletePackLogMutationVariables>;

/**
 * __useDeletePackLogMutation__
 *
 * To run a mutation, you first call `useDeletePackLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePackLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePackLogMutation, { data, loading, error }] = useDeletePackLogMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePackLogMutation(baseOptions?: Apollo.MutationHookOptions<DeletePackLogMutation, DeletePackLogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePackLogMutation, DeletePackLogMutationVariables>(DeletePackLogDocument, options);
      }
export type DeletePackLogMutationHookResult = ReturnType<typeof useDeletePackLogMutation>;
export type DeletePackLogMutationResult = Apollo.MutationResult<DeletePackLogMutation>;
export type DeletePackLogMutationOptions = Apollo.BaseMutationOptions<DeletePackLogMutation, DeletePackLogMutationVariables>;
export const UploadPresetsDocument = gql`
    mutation UploadPresets($input: LabelTemplateUploadPresetsInput!) {
  labelTemplateUploadPresets(input: $input) {
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type UploadPresetsMutationFn = Apollo.MutationFunction<UploadPresetsMutation, UploadPresetsMutationVariables>;

/**
 * __useUploadPresetsMutation__
 *
 * To run a mutation, you first call `useUploadPresetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPresetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPresetsMutation, { data, loading, error }] = useUploadPresetsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadPresetsMutation(baseOptions?: Apollo.MutationHookOptions<UploadPresetsMutation, UploadPresetsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadPresetsMutation, UploadPresetsMutationVariables>(UploadPresetsDocument, options);
      }
export type UploadPresetsMutationHookResult = ReturnType<typeof useUploadPresetsMutation>;
export type UploadPresetsMutationResult = Apollo.MutationResult<UploadPresetsMutation>;
export type UploadPresetsMutationOptions = Apollo.BaseMutationOptions<UploadPresetsMutation, UploadPresetsMutationVariables>;
export const PrintLabelsDocument = gql`
    mutation PrintLabels($input: LabelPrintInput!) {
  labelPrint(input: $input) {
    message
  }
}
    `;
export type PrintLabelsMutationFn = Apollo.MutationFunction<PrintLabelsMutation, PrintLabelsMutationVariables>;

/**
 * __usePrintLabelsMutation__
 *
 * To run a mutation, you first call `usePrintLabelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrintLabelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [printLabelsMutation, { data, loading, error }] = usePrintLabelsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrintLabelsMutation(baseOptions?: Apollo.MutationHookOptions<PrintLabelsMutation, PrintLabelsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PrintLabelsMutation, PrintLabelsMutationVariables>(PrintLabelsDocument, options);
      }
export type PrintLabelsMutationHookResult = ReturnType<typeof usePrintLabelsMutation>;
export type PrintLabelsMutationResult = Apollo.MutationResult<PrintLabelsMutation>;
export type PrintLabelsMutationOptions = Apollo.BaseMutationOptions<PrintLabelsMutation, PrintLabelsMutationVariables>;
export const RunAddOutfeedPackUsingPlanDocument = gql`
    mutation RunAddOutfeedPackUsingPlan($input: RunAddOutfeedPackUsingPlanInput!) {
  runAddOutfeedPackUsingPlan(input: $input) {
    message
    success
  }
}
    `;
export type RunAddOutfeedPackUsingPlanMutationFn = Apollo.MutationFunction<RunAddOutfeedPackUsingPlanMutation, RunAddOutfeedPackUsingPlanMutationVariables>;

/**
 * __useRunAddOutfeedPackUsingPlanMutation__
 *
 * To run a mutation, you first call `useRunAddOutfeedPackUsingPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunAddOutfeedPackUsingPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runAddOutfeedPackUsingPlanMutation, { data, loading, error }] = useRunAddOutfeedPackUsingPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRunAddOutfeedPackUsingPlanMutation(baseOptions?: Apollo.MutationHookOptions<RunAddOutfeedPackUsingPlanMutation, RunAddOutfeedPackUsingPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunAddOutfeedPackUsingPlanMutation, RunAddOutfeedPackUsingPlanMutationVariables>(RunAddOutfeedPackUsingPlanDocument, options);
      }
export type RunAddOutfeedPackUsingPlanMutationHookResult = ReturnType<typeof useRunAddOutfeedPackUsingPlanMutation>;
export type RunAddOutfeedPackUsingPlanMutationResult = Apollo.MutationResult<RunAddOutfeedPackUsingPlanMutation>;
export type RunAddOutfeedPackUsingPlanMutationOptions = Apollo.BaseMutationOptions<RunAddOutfeedPackUsingPlanMutation, RunAddOutfeedPackUsingPlanMutationVariables>;
export const UndoInventoryPackDocument = gql`
    mutation UndoInventoryPack($input: RunRemovePackInput!) {
  runRemovePack(input: $input)
}
    `;
export type UndoInventoryPackMutationFn = Apollo.MutationFunction<UndoInventoryPackMutation, UndoInventoryPackMutationVariables>;

/**
 * __useUndoInventoryPackMutation__
 *
 * To run a mutation, you first call `useUndoInventoryPackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndoInventoryPackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undoInventoryPackMutation, { data, loading, error }] = useUndoInventoryPackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUndoInventoryPackMutation(baseOptions?: Apollo.MutationHookOptions<UndoInventoryPackMutation, UndoInventoryPackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UndoInventoryPackMutation, UndoInventoryPackMutationVariables>(UndoInventoryPackDocument, options);
      }
export type UndoInventoryPackMutationHookResult = ReturnType<typeof useUndoInventoryPackMutation>;
export type UndoInventoryPackMutationResult = Apollo.MutationResult<UndoInventoryPackMutation>;
export type UndoInventoryPackMutationOptions = Apollo.BaseMutationOptions<UndoInventoryPackMutation, UndoInventoryPackMutationVariables>;
export const FindStockItemByStockCodeDocument = gql`
    query FindStockItemByStockCode($stockCode: ID!) {
  stockItem(stockCode: $stockCode) {
    ...StockItem
  }
}
    ${StockItemFragmentDoc}`;

/**
 * __useFindStockItemByStockCodeQuery__
 *
 * To run a query within a React component, call `useFindStockItemByStockCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindStockItemByStockCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindStockItemByStockCodeQuery({
 *   variables: {
 *      stockCode: // value for 'stockCode'
 *   },
 * });
 */
export function useFindStockItemByStockCodeQuery(baseOptions: Apollo.QueryHookOptions<FindStockItemByStockCodeQuery, FindStockItemByStockCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindStockItemByStockCodeQuery, FindStockItemByStockCodeQueryVariables>(FindStockItemByStockCodeDocument, options);
      }
export function useFindStockItemByStockCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindStockItemByStockCodeQuery, FindStockItemByStockCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindStockItemByStockCodeQuery, FindStockItemByStockCodeQueryVariables>(FindStockItemByStockCodeDocument, options);
        }
export type FindStockItemByStockCodeQueryHookResult = ReturnType<typeof useFindStockItemByStockCodeQuery>;
export type FindStockItemByStockCodeLazyQueryHookResult = ReturnType<typeof useFindStockItemByStockCodeLazyQuery>;
export type FindStockItemByStockCodeQueryResult = Apollo.QueryResult<FindStockItemByStockCodeQuery, FindStockItemByStockCodeQueryVariables>;
export const FindStockItemDocument = gql`
    query FindStockItem($stockCode: ID!, $barcode: String!) {
  byCode: stockItem(stockCode: $stockCode) {
    ...StockItem
  }
  byBarcode: stockItems(barcodes: [$barcode], take: 1) {
    ...StockItem
  }
}
    ${StockItemFragmentDoc}`;

/**
 * __useFindStockItemQuery__
 *
 * To run a query within a React component, call `useFindStockItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindStockItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindStockItemQuery({
 *   variables: {
 *      stockCode: // value for 'stockCode'
 *      barcode: // value for 'barcode'
 *   },
 * });
 */
export function useFindStockItemQuery(baseOptions: Apollo.QueryHookOptions<FindStockItemQuery, FindStockItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindStockItemQuery, FindStockItemQueryVariables>(FindStockItemDocument, options);
      }
export function useFindStockItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindStockItemQuery, FindStockItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindStockItemQuery, FindStockItemQueryVariables>(FindStockItemDocument, options);
        }
export type FindStockItemQueryHookResult = ReturnType<typeof useFindStockItemQuery>;
export type FindStockItemLazyQueryHookResult = ReturnType<typeof useFindStockItemLazyQuery>;
export type FindStockItemQueryResult = Apollo.QueryResult<FindStockItemQuery, FindStockItemQueryVariables>;
export const FindWorkstationByNameDocument = gql`
    query FindWorkstationByName($workstation: String!) {
  packWorkstationByName(name: $workstation) {
    id
    name
    type
    timberSmartRunTypeId
    stockAdjustmentsDisabled
    batchPrintingEnabled
    printer
    stickerPrinter
  }
}
    `;

/**
 * __useFindWorkstationByNameQuery__
 *
 * To run a query within a React component, call `useFindWorkstationByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindWorkstationByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindWorkstationByNameQuery({
 *   variables: {
 *      workstation: // value for 'workstation'
 *   },
 * });
 */
export function useFindWorkstationByNameQuery(baseOptions: Apollo.QueryHookOptions<FindWorkstationByNameQuery, FindWorkstationByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindWorkstationByNameQuery, FindWorkstationByNameQueryVariables>(FindWorkstationByNameDocument, options);
      }
export function useFindWorkstationByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindWorkstationByNameQuery, FindWorkstationByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindWorkstationByNameQuery, FindWorkstationByNameQueryVariables>(FindWorkstationByNameDocument, options);
        }
export type FindWorkstationByNameQueryHookResult = ReturnType<typeof useFindWorkstationByNameQuery>;
export type FindWorkstationByNameLazyQueryHookResult = ReturnType<typeof useFindWorkstationByNameLazyQuery>;
export type FindWorkstationByNameQueryResult = Apollo.QueryResult<FindWorkstationByNameQuery, FindWorkstationByNameQueryVariables>;
export const RecentPackLogsDocument = gql`
    query RecentPackLogs($workstationId: ID, $take: Int!, $searchQuery: String) {
  packLogs(
    packWorkstationId: $workstationId
    take: $take
    searchQuery: $searchQuery
  ) {
    id
    packNumber
    quantity
    stockItem {
      stockCode
      unitOfMeasure {
        name
      }
    }
    lastUpdated
    deleted
  }
}
    `;

/**
 * __useRecentPackLogsQuery__
 *
 * To run a query within a React component, call `useRecentPackLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentPackLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentPackLogsQuery({
 *   variables: {
 *      workstationId: // value for 'workstationId'
 *      take: // value for 'take'
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useRecentPackLogsQuery(baseOptions: Apollo.QueryHookOptions<RecentPackLogsQuery, RecentPackLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecentPackLogsQuery, RecentPackLogsQueryVariables>(RecentPackLogsDocument, options);
      }
export function useRecentPackLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecentPackLogsQuery, RecentPackLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecentPackLogsQuery, RecentPackLogsQueryVariables>(RecentPackLogsDocument, options);
        }
export type RecentPackLogsQueryHookResult = ReturnType<typeof useRecentPackLogsQuery>;
export type RecentPackLogsLazyQueryHookResult = ReturnType<typeof useRecentPackLogsLazyQuery>;
export type RecentPackLogsQueryResult = Apollo.QueryResult<RecentPackLogsQuery, RecentPackLogsQueryVariables>;
export const PrintersDocument = gql`
    query Printers {
  printers {
    id
  }
}
    `;

/**
 * __usePrintersQuery__
 *
 * To run a query within a React component, call `usePrintersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrintersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrintersQuery({
 *   variables: {
 *   },
 * });
 */
export function usePrintersQuery(baseOptions?: Apollo.QueryHookOptions<PrintersQuery, PrintersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PrintersQuery, PrintersQueryVariables>(PrintersDocument, options);
      }
export function usePrintersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrintersQuery, PrintersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PrintersQuery, PrintersQueryVariables>(PrintersDocument, options);
        }
export type PrintersQueryHookResult = ReturnType<typeof usePrintersQuery>;
export type PrintersLazyQueryHookResult = ReturnType<typeof usePrintersLazyQuery>;
export type PrintersQueryResult = Apollo.QueryResult<PrintersQuery, PrintersQueryVariables>;
export const LabelTemplatesDocument = gql`
    query LabelTemplates {
  labelTemplates {
    id
    name
    category
    presetKind
  }
}
    `;

/**
 * __useLabelTemplatesQuery__
 *
 * To run a query within a React component, call `useLabelTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLabelTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLabelTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLabelTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<LabelTemplatesQuery, LabelTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LabelTemplatesQuery, LabelTemplatesQueryVariables>(LabelTemplatesDocument, options);
      }
export function useLabelTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LabelTemplatesQuery, LabelTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LabelTemplatesQuery, LabelTemplatesQueryVariables>(LabelTemplatesDocument, options);
        }
export type LabelTemplatesQueryHookResult = ReturnType<typeof useLabelTemplatesQuery>;
export type LabelTemplatesLazyQueryHookResult = ReturnType<typeof useLabelTemplatesLazyQuery>;
export type LabelTemplatesQueryResult = Apollo.QueryResult<LabelTemplatesQuery, LabelTemplatesQueryVariables>;
export const LabelTemplateDocument = gql`
    query LabelTemplate($id: ID!) {
  labelTemplate(id: $id) {
    id
    name
    category
    defaultPrinter
    presetKind
    presets
    fields {
      name
      internalName
      preset
      extraInfo
      kind
      defaultLineCount
    }
  }
}
    `;

/**
 * __useLabelTemplateQuery__
 *
 * To run a query within a React component, call `useLabelTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useLabelTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLabelTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLabelTemplateQuery(baseOptions: Apollo.QueryHookOptions<LabelTemplateQuery, LabelTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LabelTemplateQuery, LabelTemplateQueryVariables>(LabelTemplateDocument, options);
      }
export function useLabelTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LabelTemplateQuery, LabelTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LabelTemplateQuery, LabelTemplateQueryVariables>(LabelTemplateDocument, options);
        }
export type LabelTemplateQueryHookResult = ReturnType<typeof useLabelTemplateQuery>;
export type LabelTemplateLazyQueryHookResult = ReturnType<typeof useLabelTemplateLazyQuery>;
export type LabelTemplateQueryResult = Apollo.QueryResult<LabelTemplateQuery, LabelTemplateQueryVariables>;
export const LabelTemplatePresetDownloadDocument = gql`
    query LabelTemplatePresetDownload($id: ID!) {
  labelTemplate(id: $id) {
    id
    presetDocument
  }
}
    `;

/**
 * __useLabelTemplatePresetDownloadQuery__
 *
 * To run a query within a React component, call `useLabelTemplatePresetDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useLabelTemplatePresetDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLabelTemplatePresetDownloadQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLabelTemplatePresetDownloadQuery(baseOptions: Apollo.QueryHookOptions<LabelTemplatePresetDownloadQuery, LabelTemplatePresetDownloadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LabelTemplatePresetDownloadQuery, LabelTemplatePresetDownloadQueryVariables>(LabelTemplatePresetDownloadDocument, options);
      }
export function useLabelTemplatePresetDownloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LabelTemplatePresetDownloadQuery, LabelTemplatePresetDownloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LabelTemplatePresetDownloadQuery, LabelTemplatePresetDownloadQueryVariables>(LabelTemplatePresetDownloadDocument, options);
        }
export type LabelTemplatePresetDownloadQueryHookResult = ReturnType<typeof useLabelTemplatePresetDownloadQuery>;
export type LabelTemplatePresetDownloadLazyQueryHookResult = ReturnType<typeof useLabelTemplatePresetDownloadLazyQuery>;
export type LabelTemplatePresetDownloadQueryResult = Apollo.QueryResult<LabelTemplatePresetDownloadQuery, LabelTemplatePresetDownloadQueryVariables>;
export const RunDocument = gql`
    query Run($id: ID!) {
  run(id: $id) {
    id
    complete
    date
    description
    outfeedPlan {
      lines {
        id
        linealPlanned
        piecesPlanned
        linealCompleted
        packBuild
        nextAction
        unitNumber
        wipProduct {
          id
          description
          setLength
        }
        finishedProduct {
          stockCode
          description
          thicknessMillimeters
          widthMillimeters
          lengthMillimeters
          standardPackSize
        }
      }
    }
  }
}
    `;

/**
 * __useRunQuery__
 *
 * To run a query within a React component, call `useRunQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRunQuery(baseOptions: Apollo.QueryHookOptions<RunQuery, RunQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RunQuery, RunQueryVariables>(RunDocument, options);
      }
export function useRunLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RunQuery, RunQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RunQuery, RunQueryVariables>(RunDocument, options);
        }
export type RunQueryHookResult = ReturnType<typeof useRunQuery>;
export type RunLazyQueryHookResult = ReturnType<typeof useRunLazyQuery>;
export type RunQueryResult = Apollo.QueryResult<RunQuery, RunQueryVariables>;
export const RunsDocument = gql`
    query Runs($untilDate: Date, $workstationId: ID) {
  runs(untilDate: $untilDate, workstationId: $workstationId) {
    id
    complete
    date
    description
    workstation {
      name
      type
    }
    outfeedPlan {
      lines {
        id
      }
    }
  }
}
    `;

/**
 * __useRunsQuery__
 *
 * To run a query within a React component, call `useRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunsQuery({
 *   variables: {
 *      untilDate: // value for 'untilDate'
 *      workstationId: // value for 'workstationId'
 *   },
 * });
 */
export function useRunsQuery(baseOptions?: Apollo.QueryHookOptions<RunsQuery, RunsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RunsQuery, RunsQueryVariables>(RunsDocument, options);
      }
export function useRunsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RunsQuery, RunsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RunsQuery, RunsQueryVariables>(RunsDocument, options);
        }
export type RunsQueryHookResult = ReturnType<typeof useRunsQuery>;
export type RunsLazyQueryHookResult = ReturnType<typeof useRunsLazyQuery>;
export type RunsQueryResult = Apollo.QueryResult<RunsQuery, RunsQueryVariables>;
export const AutofillPackLogsDocument = gql`
    query AutofillPackLogs($workstationId: ID!) {
  packLogs(packWorkstationId: $workstationId, take: 50) {
    id
    quantity
    deleted
    unitNumber
    tally {
      length
      pieces
    }
    stockItem {
      stockCode
      customerName
      unitOfMeasure {
        name
      }
    }
  }
}
    `;

/**
 * __useAutofillPackLogsQuery__
 *
 * To run a query within a React component, call `useAutofillPackLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutofillPackLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutofillPackLogsQuery({
 *   variables: {
 *      workstationId: // value for 'workstationId'
 *   },
 * });
 */
export function useAutofillPackLogsQuery(baseOptions: Apollo.QueryHookOptions<AutofillPackLogsQuery, AutofillPackLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AutofillPackLogsQuery, AutofillPackLogsQueryVariables>(AutofillPackLogsDocument, options);
      }
export function useAutofillPackLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AutofillPackLogsQuery, AutofillPackLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AutofillPackLogsQuery, AutofillPackLogsQueryVariables>(AutofillPackLogsDocument, options);
        }
export type AutofillPackLogsQueryHookResult = ReturnType<typeof useAutofillPackLogsQuery>;
export type AutofillPackLogsLazyQueryHookResult = ReturnType<typeof useAutofillPackLogsLazyQuery>;
export type AutofillPackLogsQueryResult = Apollo.QueryResult<AutofillPackLogsQuery, AutofillPackLogsQueryVariables>;
export const WorkstationRunsDocument = gql`
    query WorkstationRuns($runTypeId: ID!, $untilDate: Date!) {
  runs(workstationId: $runTypeId, untilDate: $untilDate) {
    id
    description
  }
}
    `;

/**
 * __useWorkstationRunsQuery__
 *
 * To run a query within a React component, call `useWorkstationRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkstationRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkstationRunsQuery({
 *   variables: {
 *      runTypeId: // value for 'runTypeId'
 *      untilDate: // value for 'untilDate'
 *   },
 * });
 */
export function useWorkstationRunsQuery(baseOptions: Apollo.QueryHookOptions<WorkstationRunsQuery, WorkstationRunsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkstationRunsQuery, WorkstationRunsQueryVariables>(WorkstationRunsDocument, options);
      }
export function useWorkstationRunsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkstationRunsQuery, WorkstationRunsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkstationRunsQuery, WorkstationRunsQueryVariables>(WorkstationRunsDocument, options);
        }
export type WorkstationRunsQueryHookResult = ReturnType<typeof useWorkstationRunsQuery>;
export type WorkstationRunsLazyQueryHookResult = ReturnType<typeof useWorkstationRunsLazyQuery>;
export type WorkstationRunsQueryResult = Apollo.QueryResult<WorkstationRunsQuery, WorkstationRunsQueryVariables>;
export const RunOutfeedDocument = gql`
    query RunOutfeed($runId: ID!) {
  run(id: $runId) {
    id
    outfeed {
      packs {
        packId
        productCode
        processDate
      }
    }
  }
}
    `;

/**
 * __useRunOutfeedQuery__
 *
 * To run a query within a React component, call `useRunOutfeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunOutfeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunOutfeedQuery({
 *   variables: {
 *      runId: // value for 'runId'
 *   },
 * });
 */
export function useRunOutfeedQuery(baseOptions: Apollo.QueryHookOptions<RunOutfeedQuery, RunOutfeedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RunOutfeedQuery, RunOutfeedQueryVariables>(RunOutfeedDocument, options);
      }
export function useRunOutfeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RunOutfeedQuery, RunOutfeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RunOutfeedQuery, RunOutfeedQueryVariables>(RunOutfeedDocument, options);
        }
export type RunOutfeedQueryHookResult = ReturnType<typeof useRunOutfeedQuery>;
export type RunOutfeedLazyQueryHookResult = ReturnType<typeof useRunOutfeedLazyQuery>;
export type RunOutfeedQueryResult = Apollo.QueryResult<RunOutfeedQuery, RunOutfeedQueryVariables>;
export const PackHistoryDocument = gql`
    query PackHistory($packWorkstationId: ID, $stockCode: ID, $take: Int, $startDate: DateTime, $endDate: DateTime, $searchQuery: String) {
  packLogs(
    packWorkstationId: $packWorkstationId
    stockCode: $stockCode
    take: $take
    startDate: $startDate
    endDate: $endDate
    searchQuery: $searchQuery
  ) {
    id
    packNumber
    workstation {
      id
      name
    }
    lastUpdated
    stockItem {
      stockCode
      description
      unitOfMeasure {
        symbolCapitalized
      }
    }
    quantity
    tally {
      length
      pieces
    }
    unitNumber
    unitNumberCategory
    deleted
  }
}
    `;

/**
 * __usePackHistoryQuery__
 *
 * To run a query within a React component, call `usePackHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackHistoryQuery({
 *   variables: {
 *      packWorkstationId: // value for 'packWorkstationId'
 *      stockCode: // value for 'stockCode'
 *      take: // value for 'take'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function usePackHistoryQuery(baseOptions?: Apollo.QueryHookOptions<PackHistoryQuery, PackHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PackHistoryQuery, PackHistoryQueryVariables>(PackHistoryDocument, options);
      }
export function usePackHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PackHistoryQuery, PackHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PackHistoryQuery, PackHistoryQueryVariables>(PackHistoryDocument, options);
        }
export type PackHistoryQueryHookResult = ReturnType<typeof usePackHistoryQuery>;
export type PackHistoryLazyQueryHookResult = ReturnType<typeof usePackHistoryLazyQuery>;
export type PackHistoryQueryResult = Apollo.QueryResult<PackHistoryQuery, PackHistoryQueryVariables>;
export const WorkstationsDocument = gql`
    query Workstations {
  packWorkstations {
    id
    name
  }
}
    `;

/**
 * __useWorkstationsQuery__
 *
 * To run a query within a React component, call `useWorkstationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkstationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkstationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkstationsQuery(baseOptions?: Apollo.QueryHookOptions<WorkstationsQuery, WorkstationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkstationsQuery, WorkstationsQueryVariables>(WorkstationsDocument, options);
      }
export function useWorkstationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkstationsQuery, WorkstationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkstationsQuery, WorkstationsQueryVariables>(WorkstationsDocument, options);
        }
export type WorkstationsQueryHookResult = ReturnType<typeof useWorkstationsQuery>;
export type WorkstationsLazyQueryHookResult = ReturnType<typeof useWorkstationsLazyQuery>;
export type WorkstationsQueryResult = Apollo.QueryResult<WorkstationsQuery, WorkstationsQueryVariables>;