import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, IconButton, Modal, Paper, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import { LabelTemplateField } from '../types';
import { UploadButton } from './PrinterPresets/PresetUploadDownload';
import {
  createExcelTemplate,
  parseExcelTemplate,
  TemplateParseRow,
} from '../utils/bulk-print';
import { BulkPrintTable } from './BulkPrintTable';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { print } from '../store/reducer';
import { PrinterSelector } from './PrinterPresets/PrinterSelector';
import { usePrinters } from '../utils/defaultPrinters';

export interface BulkPrintModalProps {
  documentName: string;
  initialPrinter: string;
  fields: LabelTemplateField[];
}

export function BulkPrintModal({
  documentName,
  initialPrinter,
  fields,
}: BulkPrintModalProps) {
  const dispatch = useDispatch();
  const printers = usePrinters() ?? [];

  type State = 'begin' | 'downloaded' | 'uploaded' | 'error' | 'done';

  const [open, setOpen] = useState(false);
  const [printer, setPrinter] = useState(initialPrinter);
  const [rows, setRows] = useState<TemplateParseRow[]>();
  const [state, setState] = useState<State>('begin');
  useEffect(() => {
    setState('begin');
    setPrinter(initialPrinter);
  }, [open, initialPrinter]);

  const totalLabels = useMemo(() => {
    if (!rows) return undefined;
    return rows.reduce((acc, v) => acc + v.quantity, 0);
  }, [rows]);

  const onClose = useCallback(() => setOpen(false), []);
  const handlePrint = useCallback(() => {
    if (!rows) return;

    setState('done');
    dispatch(
      print(
        documentName,
        rows.map((row) => {
          const data = Object.fromEntries([...row.fields.entries()]);
          return {
            documentName,
            printQuantity: row.quantity,
            printerName: printer,
            data,
          };
        }),
        false,
      ),
    );
  }, [rows, printer, dispatch, documentName]);

  return (
    <>
      <Button variant="outlined" onClick={() => setOpen(true)}>
        Bulk Print...
      </Button>
      <Modal open={open} onClose={onClose}>
        <ModalStyle
          style={
            state === 'uploaded' || state === 'done'
              ? { left: '15vw', top: '15vh', width: '65vw', height: '65vh' }
              : undefined
          }
        >
          <IconButton
            onClick={onClose}
            style={{ position: 'absolute', top: '1em', right: '1em' }}
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <div>
            <Typography variant="h5" sx={{ marginBottom: '1em' }}>
              Bulk Printing
            </Typography>

            {state !== 'uploaded' && state !== 'done' && (
              <>
                <Typography>
                  Please download the template, fill it in, and re-upload.
                </Typography>

                <div style={{ display: 'flex', gap: '1em', marginTop: '1em' }}>
                  <Button
                    variant="contained"
                    color={state === 'begin' ? 'primary' : 'secondary'}
                    onClick={() => {
                      setState('downloaded');
                      createExcelTemplate(fields.map((x) => x.name));
                    }}
                  >
                    Download
                  </Button>

                  <UploadButton
                    variant="contained"
                    color={state === 'downloaded' ? 'primary' : 'secondary'}
                    onUpload={(file) => {
                      setState('uploaded');
                      parseExcelTemplate(file, fields)
                        .then((result) => {
                          if (result.type === 'error') {
                            setState('error');
                            Swal.fire(
                              'There were some problems',
                              result.errors.join(', '),
                              'error',
                            );
                            return;
                          }

                          setRows(result.rows);
                        })
                        .catch(() => {
                          setState('error');
                          Swal.fire(
                            'Oops...',
                            'Something went wrong!',
                            'error',
                          );
                        });
                    }}
                  >
                    Upload
                  </UploadButton>
                </div>
              </>
            )}

            {(state === 'uploaded' || state === 'done') && (
              <>
                {rows ? (
                  <>
                    <PrinterSelector
                      printer={printer}
                      printers={printers}
                      setPrinter={setPrinter}
                      style={{ marginBottom: '2rem' }}
                    />

                    <Typography>
                      Please confirm you'd like to print the below
                    </Typography>
                    <BulkPrintTable
                      headers={fields.map((x) => x.name)}
                      rows={rows}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handlePrint}
                      // Prevent clicking twice
                      disabled={state === 'done' || !printer}
                    >
                      Print a total of {totalLabels}{' '}
                      {totalLabels === 1 ? 'label' : 'labels'}
                    </Button>
                  </>
                ) : (
                  <Typography>Loading file...</Typography>
                )}
              </>
            )}
          </div>
        </ModalStyle>
      </Modal>
    </>
  );
}

const ModalStyle = styled(Paper)`
  left: 30vw;
  top: 30vh;
  width: 35vw;
  height: 35vh;
  position: fixed;
  outline: none;
  padding: 2em;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  overflow: auto;
`;
