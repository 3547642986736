import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import { useUndoInventoryPackMutation } from '../../../generated/graphql';
import Swal from 'sweetalert2';

export interface RecentPackLine {
  runId: string;
  pack: {
    packId: string;
    productCode: string;
    processDate: string;
  };
}

export const RunOutfeedPackLine: React.FC<RecentPackLine> = ({
  runId,
  pack,
}) => {
  const [deletePack, { loading: deletingPack }] =
    useUndoInventoryPackMutation();

  const handleUpdate = () => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure you want to delete this pack?',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonColor: 'red',
    }).then((result) => {
      if (result.isConfirmed) {
        deletePack({
          variables: {
            input: {
              packId: pack.packId,
              runId,
              outfeed: true,
            },
          },
          refetchQueries: ['RunOutfeed'],
          awaitRefetchQueries: true,
        }).catch((err) => {
          console.error('Delete inventory pack failed:', err);
          Swal.fire({
            icon: 'error',
            title: 'Failed to delete pack',
            text: 'Perhaps the pack has already been used at another workstation?',
          });
        });
      }
    })
  };

  return (
    <ListItem divider>
      <ListItemText
        primary={pack.processDate}
        secondary="Process Date"
        style={{ textOverflow: 'ellipsis', overflow: 'hidden', flex: '1' }}
        />
      <ListItemText
        primary={pack.packId}
        secondary="Pack Number"
        style={{ textOverflow: 'ellipsis', overflow: 'hidden', flex: '1' }}
      />
      <ListItemText
        primary={pack.productCode}
        secondary="Product Code"
        style={{ textOverflow: 'ellipsis', overflow: 'hidden', flex: '1' }}
      />
      <ListItemSecondaryAction>
        {deletingPack
          ? <CircularProgress />
          : <Button onClick={handleUpdate} aria-label="delete" color="error">
              Delete
            </Button>
        }
      </ListItemSecondaryAction>
    </ListItem>
  );
};
