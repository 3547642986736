import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import styled from 'styled-components';
import { LabelTemplatePreview } from '../../types';

import DocumentPreviewRow from './DocumentPreviewRow';

export interface CategoryProps {
  categoryName: string;
  toggle: () => void;
  selected: boolean;
  documents: LabelTemplatePreview[];
  selectDocument: (docId: string, type: 'preset' | 'custom') => void;
}

export const Category: React.FC<CategoryProps> = ({
  categoryName,
  toggle,
  selected,
  documents,
  selectDocument,
}) => {
  return (
    <CategoryStyle>
      <TableRow className="Row" hover={true} onClick={() => toggle()}>
        <TableCell colSpan={6}>
          <div className="RowTitle">{categoryName}</div>
          <Collapse in={selected} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {documents.map((doc) => (
                <DocumentPreviewRow
                  key={doc.name}
                  doc={doc}
                  selectDocument={selectDocument}
                />
              ))}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </CategoryStyle>
  );
};

const CategoryStyle = styled.div`
  display: contents;
  .Row {
    cursor: pointer;
    .RowTitle {
      font-size: large;
      height: 60px;
      display: flex;
      align-items: center;
    }
  }
`;
