import React, { useState } from 'react';

import { useAppDispatch } from '../../store';
import { clearFields, print } from '../../store/reducer';
import BackToStartButton from '../BackToStartButton';
import styled from 'styled-components';
import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material';

export interface PrintFooterProps {
  documentName: string;
  printerName: string;
  data: Record<string, string>[];
}

export const PrintFooter: React.FC<PrintFooterProps> = ({
  documentName,
  printerName,
  data,
}) => {
  const [quantity, setQuantity] = useState('');
  const [shouldReset, setShouldReset] = useState(true);
  const [shouldClearFields, setShouldClearFields] = useState(true);

  const dispatch = useAppDispatch();

  const commands = (data ?? []).map((row) => ({
    documentName,
    printerName,
    printQuantity: parseInt(quantity, 10),
    data: row,
  }));

  const printAction = () => {
    dispatch(print(documentName, commands, shouldReset));
    if (!shouldReset && shouldClearFields) dispatch(clearFields());
  };

  return (
    <FooterStyle>
      <BackToStartButton />

      <div className="Checkboxes">
        <FormControlLabel
          control={
            <Checkbox
              checked={shouldReset}
              onChange={(e) => setShouldReset(e.target.checked)}
              id="shouldResetCheckbox"
              color="primary"
            />
          }
          label="Return to Start after Printing"
        />
        <FormControlLabel
          style={{ visibility: shouldReset ? 'hidden' : 'visible' }}
          control={
            <Checkbox
              checked={shouldClearFields}
              onChange={(e) => setShouldClearFields(e.target.checked)}
              id="shouldResetCheckbox"
              color="primary"
            />
          }
          label="Clear fields after printing"
        />
      </div>
      <div className="Rhs">
        <TextField
          className="Quantity"
          id="standard-number"
          label="Quantity"
          type="number"
          InputProps={{ inputProps: { min: 0 } }}
          value={quantity}
          variant="outlined"
          onChange={(e) => setQuantity(e.target.value)}
        />

        <Button
          color="primary"
          variant="contained"
          disabled={
            quantity === '' ||
            printerName === '' ||
            documentName === '' ||
            !data
          }
          onClick={printAction}
        >
          Print
        </Button>
      </div>
    </FooterStyle>
  );
};

const FooterStyle = styled.div`
  width: 100%;
  align-items: center;
  padding: 6px;
  background-color: #f3f3f3;
  box-sizing: border-box;
  box-shadow: 0 -0.15rem 0.1rem rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-evenly;

  .Checkboxes {
    display: flex;
    flex-direction: column;
    label {
      margin: 0;
    }
  }

  .Rhs {
    display: flex;
    align-items: center;
  }

  .Quantity {
    margin-right: 20px;
  }
`;
