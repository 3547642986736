import Button from '@mui/material/Button';
import React from 'react';
import { useAppDispatch } from '../store';
import { AppActions } from '../store/reducer';

const BackToStartButton: React.FC = () => {
  const dispatch = useAppDispatch();
  return (
    <Button
      variant="contained"
      color="error"
      onClick={() => dispatch(AppActions.reset({}))}
    >
      Back to Start
    </Button>
  );
};

export default BackToStartButton;
