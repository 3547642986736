import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import dateformat from 'dateformat';
import React from 'react';
import { PackLog, useDeletePackLogMutation } from '../generated/graphql';
import { displayUserErrors } from '../utils/errors';

export interface RecentPackLine {
  recentPack: PackLog;
}

export const RecentPackLine: React.FC<RecentPackLine> = ({ recentPack }) => {
  return (
    <ListItem
      key={recentPack.id}
      divider
      style={{ color: recentPack.deleted ? '#dcdcdc' : 'black', flex: '1' }}
    >
      <ListItemText
        primary={recentPack.packNumber}
        secondary="Pack Number"
        style={{ textOverflow: 'ellipsis', overflow: 'hidden', flex: '1' }}
      />
      <ListItemText
        primary={recentPack.stockItem.stockCode}
        secondary="Stock Code"
        style={{ textOverflow: 'ellipsis', overflow: 'hidden', flex: '1' }}
      />
      <ListItemText
        primary={`${recentPack.quantity} (${recentPack.stockItem.unitOfMeasure.name})`}
        secondary="Qty"
        style={{ textOverflow: 'ellipsis', overflow: 'hidden', flex: '1' }}
      />
      <ListItemText
        primary={dateformat(recentPack.lastUpdated)}
        secondary="Printed "
        style={{ textOverflow: 'ellipsis', overflow: 'hidden', flex: '1' }}
      />
      <ListItemSecondaryAction>
        <DeletePackListAction
          packId={recentPack.id}
          deleted={recentPack.deleted}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export function DeletePackListAction({
  packId,
  deleted,
}: {
  packId: string;
  deleted: boolean;
}) {
  const [deletePackLog, { loading: deleting }] = useDeletePackLogMutation();

  const handleUpdate = (action: 'delete' | 'restore') => {
    displayUserErrors(
      'stockCreateUndo',
      deletePackLog({
        variables: {
          input: {
            packLogId: packId,
            restore: action === 'restore',
          },
        },
      }),
      true,
    );
  };

  if (deleting) {
    return <CircularProgress size="1.5em" />;
  }

  if (deleted) {
    return (
      <Button onClick={() => handleUpdate('restore')} color="error">
        Restore
      </Button>
    );
  }

  return (
    <Button onClick={() => handleUpdate('delete')} color="error">
      Delete
    </Button>
  );
}
