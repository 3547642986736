import React from 'react';

export const ErrorMessage: React.FC = () => (
  <div>
    Something went wrong{' '}
    <span role="img" aria-label="sad">
      😞
    </span>
    , maybe try to refresh
  </div>
);
