import React, { Suspense } from 'react';
import {
  PackWorkstationType as PWT,
  useFindWorkstationByNameQuery,
} from '../../generated/graphql';
import { Error } from '../Utility/Error';
import { Loading } from '../Utility/Loading';

const StandardWorkstation = React.lazy(
  () => import('./Standard/StandardWorkstation'),
);
const PlannedWorkstation = React.lazy(
  () => import('./Planned/PlannedWorkstation'),
);
const OptiWorkstation = React.lazy(() => import('./Opti/OptiWorkstation'));
const AddedValueWorkstation = React.lazy(
  () => import('./AddedValueWorkstation'),
);

export const WorkstationRoutes: React.FC = () => {
  const items = window.location.pathname.split('/');
  const name = items.pop() || items.pop() || '';
  const { data, loading, error } = useFindWorkstationByNameQuery({
    variables: { workstation: name },
  });

  const workstation = data?.packWorkstationByName;

  if (loading) return <Loading />;
  if (error || !workstation) {
    return <Error errorText="Workstation not found, please contact IT." />;
  }

  const map: Record<PWT, JSX.Element> = {
    [PWT.Standard]: <StandardWorkstation workstation={workstation} />,
    [PWT.Planned]: <PlannedWorkstation workstation={workstation} />,
    [PWT.Opti]: <OptiWorkstation workstation={workstation} />,
    [PWT.AddedValue]: <AddedValueWorkstation workstation={workstation} />,
  };

  if (map[workstation.type]) {
    return (
      <Suspense fallback={<Loading fullscreen />}>
        {map[workstation.type]}
      </Suspense>
    );
  }

  return (
    <Error errorText="Workstation type not supported. Please contact IT." />
  );
};
