import { useState } from 'react';
import { usePrintersQuery } from '../generated/graphql';

export function setDefaultPrinter(documentId: string, printer: string) {
  localStorage.setItem(`document-${documentId}-saved-printer`, printer);
}

export function getDefaultPrinter(documentId: string) {
  return localStorage.getItem(`document-${documentId}-saved-printer`);
}

export function usePrinters() {
  const { data } = usePrintersQuery();
  return data?.printers.map((x) => x.id) ?? null;
}

export function useDefaultPrinter(document?: {
  id: string;
  defaultPrinter?: string | null;
}): [string, string[], (printer: string) => void] {
  const [printer_, setPrinter_] = useState('');
  const printers = usePrinters() ?? [];
  const setPrinter = (printer: string) => {
    if (document) setDefaultPrinter(document.id, printer);
    setPrinter_(printer);
  };

  if (!document) return [printer_, printers, setPrinter];

  const documentDefaultPrinter = printers.includes(document?.defaultPrinter!)
    ? document.defaultPrinter
    : null;

  let savedPrinter = getDefaultPrinter(document.id);
  if (!printers.includes(savedPrinter!)) savedPrinter = null;

  const printer = savedPrinter || documentDefaultPrinter || printer_;
  return [printer, printers, setPrinter];
}
