import { useCallback } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from './';
import { AppActions } from './reducer';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function useCurrentRunId() {
  const dispatch = useAppDispatch();

  const currentRunId = useAppSelector((s) => s.currentRunId);
  const setCurrentRunId = useCallback(
    (runId: string | null) => {
      dispatch(AppActions.setCurrentRunId(runId));
    },
    [dispatch],
  );

  return [currentRunId, setCurrentRunId] as const;
}
