import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  FindWorkstationByNameQuery,
  useRunsQuery,
} from '../../../generated/graphql';
import { useCurrentRunId } from '../../../store';
import { Error } from '../../Utility/Error';
import { Loading } from '../../Utility/Loading';
import ProductSelect from './ProductSelect';

const RUNS_NOW_UPDATE_MS = 300_000;

export interface PlannedWorkstationProps {
  workstation: NonNullable<FindWorkstationByNameQuery['packWorkstationByName']>;
}

const PlannedWorkstation: React.FC<PlannedWorkstationProps> = ({
  workstation,
}) => {
  const timberSmartRunTypeId = workstation.timberSmartRunTypeId!;
  const [selectedRunId, setSelectedRunId] = useCurrentRunId();

  const now = useNow(RUNS_NOW_UPDATE_MS);

  const { data, loading, error } = useRunsQuery({
    variables: {
      workstationId: timberSmartRunTypeId,
      // Since this changes periodically, the `data` is updated regularly with
      // any new runs that are created (or deleted)
      untilDate: addDays(now, 7).toISOString(),
    },
  });

  if (loading) {
    return <Loading />;
  }
  if (error || !data) {
    return (
      <Error fullscreen errorText={'Error fetching runs, please contact IT.'} />
    );
  }

  if (selectedRunId) {
    return (
      <ProductSelect
        runId={selectedRunId}
        back={() => setSelectedRunId(null)}
        timberSmartRunTypeId={timberSmartRunTypeId}
      />
    );
  }

  return (
    <RunSelectStyle>
      <Typography variant="h4" className="header">
        Run Select
      </Typography>
      <TableContainer>
        <div
          style={{
            height: 'calc(100vh - 16rem)',
            overflow: 'auto',
          }}
        >
          <Table stickyHeader style={{ marginBottom: '16em' }}>
            <TableHead>
              <TableRow>
                <TableCell>Run</TableCell>
                <TableCell>Description</TableCell>
                <TableCell align="center">Planned Start Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.runs.length > 0 ? (
                data.runs
                  .filter((row) => row.outfeedPlan.lines.length >= 1)
                  .sort((a, b) => {
                    return (
                      new Date(a.date).getTime() - new Date(b.date).getTime()
                    );
                  })
                  .map((row) => (
                    <TableRow
                      key={row.id}
                      onClick={() => {
                        setSelectedRunId(row.id);
                      }}
                      style={{ height: '6em' }}
                    >
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.description}</TableCell>
                      <TableCell align="center">{row.date}</TableCell>
                    </TableRow>
                  ))
              ) : (
                <Typography style={{ textAlign: 'center' }} variant="h3">
                  No runs found. 😵
                </Typography>
              )}
            </TableBody>
          </Table>
        </div>
      </TableContainer>
    </RunSelectStyle>
  );
};

export default PlannedWorkstation;

export function addDays(date: Date, days: number) {
  const result = new Date(date.getTime());
  result.setDate(date.getDate() + days);
  return result;
}

export function useNow(intervalMs: number) {
  const [now, setNow] = useState(new Date());
  useEffect(() => {
    const sub = setTimeout(() => {
      const newDate = new Date();
      setNow(newDate);
    }, intervalMs);
    return () => {
      clearTimeout(sub);
    };
  }, [now, intervalMs]);
  return now;
}

const RunSelectStyle = styled.div`
  margin-left: 1em;
  margin-right: 1em;

  animation: fadeIn 0.5s;

  .header {
    margin: 20px 0;
    text-align: center;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
