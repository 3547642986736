
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "FileInfo": [
      "DeletedFile",
      "PresentFile"
    ],
    "LabelTemplateUploadPresetsError": [
      "LabelTemplateUploadPresetsInvalidDataError",
      "LabelTemplateUploadPresetsNotFoundError"
    ],
    "RunAddInfeedPacksError": [
      "DuplicatePackError",
      "IncompatiblePackError",
      "PackDoesNotExistError",
      "RunDoesNotExistError"
    ],
    "StockConsumeError": [
      "StockConsumeNotFoundError"
    ],
    "StockCreateError": [
      "StockCreateInvalidInputError",
      "StockCreateNotFoundError"
    ],
    "StockCreateSingleError": [
      "StockCreateSingleNoBarcodeError",
      "StockCreateSingleNotFoundError"
    ],
    "StockCreateSingleUndoError": [
      "StockCreateSingleUndoNotFoundError"
    ],
    "StockCreateUndoError": [
      "StockCreateUndoNotFoundError"
    ],
    "UpdateHandlesGetError": [
      "UpdateHandlesGetNotFoundError",
      "UpdateHandlesGetNotModifiableError"
    ],
    "UploadHandlesGetError": [
      "UploadHandlesGetInvalidInputError"
    ],
    "UserError": [
      "LabelTemplateUploadPresetsInvalidDataError",
      "LabelTemplateUploadPresetsNotFoundError",
      "StockConsumeNotFoundError",
      "StockCreateInvalidInputError",
      "StockCreateNotFoundError",
      "StockCreateSingleNoBarcodeError",
      "StockCreateSingleNotFoundError",
      "StockCreateSingleUndoNotFoundError",
      "StockCreateUndoNotFoundError",
      "UpdateHandlesGetNotFoundError",
      "UpdateHandlesGetNotModifiableError",
      "UploadHandlesGetInvalidInputError"
    ]
  }
};
      export default result;
    