import { MenuItem, TextField } from '@mui/material';
import { useWorkstationsQuery } from '../generated/graphql';

export interface SelectWorkstationProps {
  workstationId: string | null;
  setWorkstationId: (id: string | null) => void;
  disabled?: boolean;
}

export function SelectWorkstation({
  workstationId,
  setWorkstationId,
  disabled,
}: SelectWorkstationProps) {
  const { data } = useWorkstationsQuery();

  return (
    <TextField
      style={{ width: 200 }}
      select
      disabled={disabled}
      label="Filter Workstation"
      variant="standard"
      value={workstationId || '-1'}
      onChange={(e) => {
        const value = e.target.value as string;
        setWorkstationId(value === '-1' ? null : value);
      }}
    >
      {[
        <MenuItem key={-1} value="-1">
          <em>All</em>
        </MenuItem>,
        ...(data?.packWorkstations ?? []).map((workstation) => (
          <MenuItem key={workstation.id} value={workstation.id}>
            {workstation.name}
          </MenuItem>
        )),
      ]}
    </TextField>
  );
}
