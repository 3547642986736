import React, { useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useAppDispatch, useAppSelector } from '../../store';
import { AppActions } from '../../store/reducer';
import { PackWorkstation } from '../../generated/graphql';

export interface WorkstationPrinterSelectorProps {
  printers: string[];
  workstation: PackWorkstation;
}

export const WorkstationPrinterSelector: React.FC<
  WorkstationPrinterSelectorProps
> = ({ printers, workstation }) => {
  const dispatch = useAppDispatch();

  const selectedPrinter = useAppSelector((s) => s.printer);

  const p = workstation.printer;
  useEffect(() => {
    const printer = p.split('\\').pop();
    if (printer != null) {
      dispatch(AppActions.setPrinter(printer));
    }
  }, [p, dispatch]);

  return (
    <FormControl style={{ minWidth: 260, margin: '0 30px' }}>
      <InputLabel id="demo-simple-select-label">Select Printer</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedPrinter}
        variant="standard"
        onChange={(e) =>
          dispatch(AppActions.setPrinter(e.target.value as string))
        }
      >
        {printers &&
          printers.map((printer: string, index: number) => (
            <MenuItem
              key={index}
              value={printer.split('\\').pop()}
              style={{
                fontWeight:
                  printer && printer.split('\\').pop() === printer
                    ? 800
                    : undefined,
              }}
            >
              {printer.split('\\').pop()}
              {printer && printer.split('\\').pop() === printer && '*'}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
